import $ from 'jquery';
import qs from 'qs';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
    sendContactus, setLayout, setPath, setPathLayout,
    paymentCustomerValidation,
} from '../../actions/Utils';
import axiosInstance, { version } from '../../config/Api';

import DatePicker from "react-datepicker";
import { DateInput } from '../contactus/Dateinput';
import "react-datepicker/dist/react-datepicker.css";
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { Redirect, withRouter } from 'react-router-dom';

import Cookies from 'js-cookie'
import LangData from '../common/LangData';

class Stepthreeform extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validator: {
                policy_number: '',
                policy_holder_dob: '',
                policy_mobilenumber: '',
                policy_email: '',
                user_agree: '',
            },
            message: '',
            message_type: '',
            redirect:false,
            customer:null


        };
        // this.refs = React.createRef();

        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleMessageClose = () => {
        this.setState({ message: '', message_type: '' })
    }

    handleDateChange(date) {
        this.setState({
            startDate: date
        })
    }

    componentDidMount = () => {

    }

    // handleSubmit = () => {

    //     const is_agree = this.user_agree.checked;
    //     console.log('is_agree =',is_agree);
    
    // }

    handleSubmit = () => {



        const { formdata, validator } = this.state;

        const userFormData = {
            policy_holder_dob: $("#dateofbirth").val(),
            policy_number: this.policy_number.value,
            policy_mobilenumber: this.policy_mobilenumber.value,
            policy_email: this.policy_email.value,
            user_agree: this.user_agree.checked?1:'',
        }
        console.log("this.user_agree.value",this.user_agree.checked);

        const resetValidator = {
            policy_number: '',
            policy_holder_dob: '',
            policy_mobilenumber: '',
            policy_email: '',
            user_agree: '',
        }
        var validation = {};

        if (userFormData.policy_number == '') {
            validation.policy_number = 'The policy number field is required.';
        }
        if (userFormData.policy_holder_dob == '') {
            validation.policy_holder_dob = 'The date of birth field is required.';
        }
        if (userFormData.policy_mobilenumber == '') {
            validation.policy_mobilenumber = 'The mobile number field is required.';
        }
        if (userFormData.policy_email == '') {
            validation.policy_email = 'The email field is required.';
        }
        if (this.user_agree.checked == false) {
            validation.user_agree = 'Please agree our terms and policy.';
        }



        if (Object.keys(validation).length) {
            this.setState({ validator: { ...resetValidator, ...validation }, message: '',});
        }
        else {
            var error_validate = {};
            this.props.paymentCustomerValidation(userFormData).then((res) => {

               
                if(res.status == 'success') {
                    $("#paymentform").trigger('reset');
                    this.setState({ customer:res,message: res.message, message_type: 'success', validator: { ...resetValidator },redirect:true })
                   
                }
                else {
                    this.setState({ message: res.message, message_type: 'danger', validator: { ...resetValidator } })
                }
                
               
                // this.handlePayOnline(res);
            })
                .catch(error => {
                    console.log("Hello Policy form =", error);
                    if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                                html += value + '</br>';
                                if (typeof validator[key] != "undefined") {
                                    // error_validate[key] = `${value}`;
                                    error_validate[`${key}`] = `${value}`;
                                    // Object.assign(error_validate, {key: value});
                                }

                            }

                        }
                        // console.log(' error.response.data ',error.response.data);
                        // console.log(' error.response.status ',error.response.status);
                        // console.log('error.response.headers ',error.response.headers);
                    }

                    console.log("error_validate =", error_validate);
                    console.log("validator =", validator);
                    this.setState({ validator: { ...validation, ...error_validate }})
                })
        }
    }




    handlePoliciesBasedOnAge = () => {

        var dob = $("#dateofbirth").val();

    }


    handlePorichoy = () => {

        let nid = this.policy_holder_nid.value;
        let dob = $("#dateofbirth").val();
        var date_of_birth = this.dateFormate(dob);

        const requestData = {
            "national_id": nid,
            "person_dob": date_of_birth,
            "english_translation": true
        }
        const validation = {};
        console.log('nid.length=', nid.length);
        if (nid.length == 10 || nid.length == 13 || nid.length == 17) {
            validation.policy_holder_nid = '';
            this.setState({ validator: { ...this.state.validator, ...validation } })

        }
        else {
            if (nid.length > 10) {
                validation.policy_holder_nid = 'NID must be 10, 13 or 17 digits.';
                this.setState({ validator: { ...this.state.validator, ...validation } })
            }
            return false;
        }

        if (nid && dob) {
            console.log('nid nominee.if=', nid.length);
            this.props.customerPoricyNid(requestData).then((data) => {
                if (data.passKyc === 'yes') {
                    this.setState({
                        policyholder_nid: data
                    })
                } else {
                    this.setState({
                        policyholder_nid: null
                    })
                }
            });
        }
    }


    dateFormate = (date) => {

        var today = new Date(date);
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return today = yyyy + '/' + mm + '/' + dd;

    }

    handlePayOnline = (policycustomer) => {

        axiosInstance.post(version + 'pay_online', { params: policycustomer }).then(({ data }) => {
            console.log(" hanldePayonline =", data);
            if (data.status == 'SUCCESS' && data.GatewayPageURL) {
                Cookies.set('cus_id', policycustomer.id);
                window.location.href = data.GatewayPageURL;
            }

        });
    }

    render() {

        // const contents = this.props.data.content;
        const { validator, redirect,customer } = this.state;

        if(redirect === true) {
            return <Redirect to={{
                pathname: '/payment-step-2',
                state: { customer:customer  }
            }} />
        }


        return (
            <Fragment>
                <form action="#" id="paymentform">

                    <div className="mt-3">
                        {(this.state.message != '' && this.state.message_type == 'success') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                {this.state.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                        {(this.state.message != '' && this.state.message_type == 'danger') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                {this.state.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                        {/* <form> */}
                        <div class="row">
                            <div class="col-md-6">
                                <label> <LangData keyname="label_policy_number" /><span>*</span></label>
                                <input type="text" placeholder="Policy Number" ref={(input) => this.policy_number = input} class="form-control" />

                                {validator.policy_number && <span className="help-block form-text text-danger">{validator.policy_number}</span>}

                            </div>
                            <div class="col-md-6">
                                <label> <LangData keyname="label_dob" /><span>*</span></label>
                                <DateInput
                                    id="dateofbirth"
                                    handlePoliciesBasedOnAge={() => this.handlePoliciesBasedOnAge()}
                                />
                                {validator.policy_holder_dob && <span className="help-block form-text text-danger">{validator.policy_holder_dob}</span>}


                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label> <LangData keyname="label_mobile_number" /><span>*</span></label>
                                <input type="text" placeholder="Mobile Number" ref={(input) => this.policy_mobilenumber = input} class="form-control" />

                                {validator.policy_mobilenumber && <span className="help-block form-text text-danger">{validator.policy_mobilenumber}</span>}
                            </div>


                            <div class="col-md-6">
                                <label> <LangData keyname="label_emal" /><span>*</span></label>
                                <input type="email" placeholder="Email" ref={(input) => this.policy_email = input} class="form-control" />

                                {validator.policy_email && <span className="help-block form-text text-danger">{validator.policy_email}</span>}
                            </div>
                        </div>

                        <p>
                           
                            <LangData keyname="label_payment_step_one_description" />
                        </p>

                        <div class="form-check mb-5 mt-5">
                            <input type="checkbox" ref={(input) => this.user_agree = input} class="form-check-input" />
                            <label class="form-check-label"> <LangData keyname="label_i_agree" /></label>
                                <br></br>
                            {validator.user_agree && <span className="help-block form-text text-danger">{validator.user_agree}</span>}
                        </div>

                        <button class="btn btn-primary" onClick={() => this.props.handleBackHistory()}><LangData keyname="label_back" /></button>
                        <button class="btn btn-secondary ml-3" type='button' onClick={() => this.handleSubmit()}><LangData keyname="label_proceed" /></button>



                        {/* </form> */}
                    </div>

                </form>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
    sendContactus: (requestData) => { dispatch(sendContactus(requestData)) },
    paymentCustomerValidation: (requestData) => dispatch(paymentCustomerValidation(requestData)),
})

export default withRouter(connect(null, mapDispatchToProps)(Stepthreeform));