import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { loginUser } from '../../actions/Users';
import { register } from '../../actions/Users';
import BannerLeft from '../page/BannerLeft';
import { signUpByPolicy,verifyPin,signUp,getUserInfo } from '../../actions/Users';
import InnerHTML from 'dangerously-set-inner-html';
import Cookies from 'js-cookie'
import $ from 'jquery';

import {
    Link
} from "react-router-dom";
import BreadCrumbs from '../page/BreadCrumbs';
import LangData from '../common/LangData';

class SignupPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validator_policy: {
                'policy_code': '',
            },
            validator_pin: {
                'pin_number': '',
                'policy_code': '',
            },
            validator_signup: {
                'policy_code': '',
                'username': '',
                'password': '',
                'password_confirmation': '',
            },
            message: '',
            message_type: '',
            signup_step: Cookies.get('signup_step')?Cookies.get('signup_step'):1,
            customerInfo:null,

        };
        this.refs = React.createRef();
    }

    componentDidMount = () => {
        if(!Cookies.get('policy_code')) {
            return false;
        }
        else {

        const signup_step = Cookies.get('signup_step');
        const requestData = {
            policy_code: Cookies.get('policy_code')
        }
        if(signup_step == 2) {
                this.props.getUserInfo(requestData).then(res => {

                    if (res.status == 'success') {
                        Cookies.set('signup_step', 2);
                        this.setState({ message: res.message, message_type: 'success', validator: { policy_code: '' }, signup_step: 2,customerInfo:res.data })
                    }
                    else {
                        this.setState({ message: res.message, message_type: 'danger', validator: { policy_code: '',signup_step: 1 } })
                    }


                })
            }
        }
    }

    handlePolicy = () => {


        const userFormData = {
            'policy_code': this.refs.policy_code.value,
        }

        const resetValidator = {
            'policy_code': '',
        }
        var validation = {};

        if (userFormData.policy_code == '') {
            validation.policy_code = 'The policy number field is required.';
        }


        if (Object.keys(validation).length) {
            this.setState({ validator_policy: { ...resetValidator, ...validation }, message: '' });

        }
        else {

            this.props.signUpByPolicy(userFormData).then(res => {

                if (res.status == 'success') {
                    Cookies.set('signup_step', 2);
                    Cookies.set('policy_code', userFormData.policy_code);
                    this.setState({ message: res.message, message_type: 'success', validator: { policy_code: '' }, signup_step: 2,customerInfo:res.data })
                }
                else {
                    this.setState({ message: res.message, message_type: 'danger', validator: { policy_code: '' } })
                }


            })
                .catch(error => {
                    if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                                html += `${value}` + '</br>';
                            }

                        }
                        console.log(' error.response.data ', error.response.data);
                        console.log(' error.response.status ', error.response.status);
                        console.log('error.response.headers ', error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the 
                        // browser and an instance of
                        // http.ClientRequest in node.js
                        // console.log('error.request ',error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        // console.log('Error', error.message);
                    }
                    if (html) {
                        this.setState({ message: html, message_type: 'danger', validator: { policy_code: '' } })
                    }

                })
        }


    }
    handleVerifyPin = () => {


        const userFormData = {
            'pin_number': this.refs.policy_pin.value,
            'policy_code': Cookies.get('policy_code'),
        }

        const resetValidator = {
            'pin_number': '',
            'policy_code': '',
        }
        var validation = {};

        if (userFormData.pin_number == '') {
            validation.pin_number = 'The PIN number is required.';
        }
        if (userFormData.policy_code == '') {
            validation.policy_code = 'You try to wrong process.';
        }


        if (Object.keys(validation).length) {
            this.setState({ validator_pin: { ...resetValidator, ...validation }, message: '' });

        }
        else {

            this.props.verifyPin(userFormData).then(res => {

                if (res.status == 'success') {
                    Cookies.set('signup_step', 3);
                    this.setState({ message: res.message, message_type: 'success', validator: { policy_code: '' }, signup_step: 3 })
                }
                else {
                    this.setState({ message: res.message, message_type: 'danger', validator: { policy_code: '' } })
                }


            })
                .catch(error => {
                    if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                                html += `${value}` + '</br>';
                            }

                        }
                        console.log(' error.response.data ', error.response.data);
                        console.log(' error.response.status ', error.response.status);
                        console.log('error.response.headers ', error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the 
                        // browser and an instance of
                        // http.ClientRequest in node.js
                        // console.log('error.request ',error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        // console.log('Error', error.message);
                    }
                    if (html) {
                        this.setState({ message: html, message_type: 'danger', validator: { policy_code: '' } })
                    }

                })
        }


    }
    handleSignup = () => {


        const userFormData = {
            'policy_code': Cookies.get('policy_code'),
            'username':this.refs.username.value,
            'password':this.refs.password.value,
            'password_confirmation':this.refs.password_confirmation.value,
        }

        const resetValidator = {
            'policy_code': '',
            'username': '',
            'password': '',
            'password_confirmation': '',
        }
        var validation = {};

        if (userFormData.policy_code == '') {
            validation.policy_code = 'You are wrong process.';
        }
        if (userFormData.username == '') {
            validation.username = 'You try to wrong process.';
        }
        if (userFormData.password == '') {
            validation.password = 'You try to wrong process.';
        }
        if (userFormData.password_confirmation == '') {
            validation.password_confirmation = 'You try to wrong process.';
        }


        if (Object.keys(validation).length) {
            this.setState({ validator_pin: { ...resetValidator, ...validation }, message: '' });

        }
        else {

            this.props.signUp(userFormData).then(res => {

                if (res.status == 'success') {
                    
                    this.setState({ message: res.message, message_type: 'success', validator: { policy_code: '' }, signup_step: 3 });

                    $('#signup').trigger("reset");
                    Cookies.set('signup_step', 1);
                }
                else {
                    this.setState({ message: res.message, message_type: 'danger', validator: { policy_code: '' } })
                }


            })
                .catch(error => {
                    if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                                html += `${value}` + '</br>';
                            }

                        }
                        console.log(' error.response.data ', error.response.data);
                        console.log(' error.response.status ', error.response.status);
                        console.log('error.response.headers ', error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the 
                        // browser and an instance of
                        // http.ClientRequest in node.js
                        // console.log('error.request ',error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        // console.log('Error', error.message);
                    }
                    if (html) {
                        this.setState({ message: html, message_type: 'danger', validator: { policy_code: '' } })
                    }

                })
        }


    }
    handleMessageClose = () => {
        this.setState({ message: '', message_type: '' })
    }

    handleSubmit = async event => {
        event.preventDefault();

        //
        //const response = await API.delete(`users/${this.state.id}`);

        // console.log(response);
        // console.log(response.data);
    };

    render() {
        // console.log("Sign Up page");
        const { validator_policy, validator_pin, validator_signup, signup_step,customerInfo } = this.state;
        const { lang } = this.props;
        const bread_cumbs = [
            {
                bn_title: "নিবন্ধন করুন",
                title: "Sign Up",
                url: "/signup",
            },
            {
                bn_title: "হোম",
                title: "Home",
                url: "/",
            }
        ]
        return (
            <Fragment>

                {/* <BannerLeft title={lang == 'bn' ? 'নিবন্ধন করুন' : 'Sign Up'} /> */}
                <BreadCrumbs bread_curmbs={bread_cumbs} />
                <section class="sign-up">
                    <div className="container">


                        <form id="signup" onSubmit={(event)=>this.handleSubmit(event)}>

                            {this.state.message != '' && this.state.message_type == 'success' && <div className="col-md-12" style={{ marginTop: '10px' }}>
                                <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                    <InnerHTML html={this.state.message} />
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>}
                            {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-md-12" style={{ marginTop: '10px' }}>
                                <div className="alert  alert-danger alert-dismissible fade show" role="alert">

                                    <InnerHTML html={this.state.message} />
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>}

                            {signup_step == 1 && <Fragment>

                                {lang === 'en' && <Fragment><h3>Sign Up</h3></Fragment>}
                                {lang === 'bn' && <Fragment><h3>নিবন্ধন করুন</h3></Fragment>}


                                <div className="row">

                                    <div className="col-md-12">
                                        <input type="text" ref="policy_code" className="form-control" placeholder='Enter your Policy Number' required autofocus />
                                        {validator_policy.policy_code && <span className="help-block form-text text-danger">{validator_policy.policy_code}</span>}
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <button type="button" className="btn btn-primary btn-block" onClick={() => this.handlePolicy()}>
                                        
                                        <LangData keyname="label_submit" />
                                    </button>

                                </div>
                            </Fragment>}
{/* ==================================Policy Pin =================================================*/}
{/* ==================================Policy Pin =================================================*/}
{/* ==================================Policy Pin =================================================*/}
{/* ==================================Policy Pin =================================================*/}
                            {signup_step == 2 && <Fragment>

                                {lang === 'en' && <Fragment><h3>Sign Up</h3></Fragment>}
                                {lang === 'bn' && <Fragment><h3>নিবন্ধন করুন</h3></Fragment>}


                                <p>We've sent a 4-digit one time PIN in your phone</p>
                                {customerInfo && <Fragment><p className='phone-number-tag'>
                                    {customerInfo.policy_holder_mobile_number}
                                </p></Fragment>}


                                <div className="row">

                                    <div className="col-md-12">
                                        <input type="text" ref="policy_pin" className="form-control" placeholder='Please enter 4-digit one time pin' required autofocus />
                                        {validator_pin.username && <span className="help-block form-text text-danger">{validator_pin.username}</span>}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <button type="button" className="btn btn-primary btn-block" onClick={() => this.handleVerifyPin()}>
                                        
                                        <LangData keyname="label_verify" />
                                    </button>
                                </div>
                            </Fragment>}
{/* ==================================Policy Signup =================================================*/}
{/* ==================================Policy Signup =================================================*/}
{/* ==================================Policy Signup =================================================*/}
{/* ==================================Policy Signup =================================================*/}
                            {signup_step == 3 &&<Fragment>
                                <h3><LangData keyname="label_signup_title" /></h3>

                                <div className="row">
                                
                                    <div className="col-md-12">
                                        <input type="text" ref="username" className="form-control" placeholder='Username' required autofocus />
                                        {validator_signup.username && <span className="help-block form-text text-danger">{validator_signup.email}</span>}
                                    </div>
                                </div>

                                <div className="row">
                                    
                                    <div className="col-md-12">
                                        <input type="password"  ref="password" className="form-control" placeholder='Password' required autofocus />
                                        {validator_signup.username && <span className="help-block form-text text-danger">{validator_signup.username}</span>}
                                    </div>
                                </div>

                                <div className="row">
                                
                                    <div className="col-md-12">
                                        <input type="password" placeholder='Re-type password' ref="password_confirmation" id="password" className="form-control" required />
                                        {validator_signup.password && <span className="help-block form-text text-danger">{validator_signup.password}</span>}
                                    </div>
                                </div>



                                <div className="col-md-12">
                                    <button type="button" className="btn btn-primary btn-block" onClick={()=>this.handleSignup()}>
                                        
                                        <LangData keyname="label_submit" />
                                    </button>
                                
                                </div>
                            </Fragment>}

                        </form>



                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    lang: state.utils.lang
})

const mapDispatchToProps = dispatch => ({
    loginUser: (requestData) => dispatch(loginUser(requestData)),
    register: (requestData) => dispatch(register(requestData)),
    signUpByPolicy: (requestData) => dispatch(signUpByPolicy(requestData)),
    verifyPin: (requestData) => dispatch(verifyPin(requestData)),
    signUp: (requestData) => dispatch(signUp(requestData)),
    getUserInfo: (requestData) => dispatch(getUserInfo(requestData))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignupPolicy);