import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { layoutContentLoad, getSetting, setPathLayout } from "../../actions/Utils";
import Stepthreeform from './Stepthreeform';
import Htmlcontent from '../Htmlcontent';
import BreadCrumbs from '../page/BreadCrumbs';
import { image_url } from '../../config/Api';
import LangData from '../common/LangData';
import { isArray } from 'lodash';


class Paydirectstepthree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            container: {},
            footer: {},
            layout_id: '',
            layout_content: null,
            message: '',
            message_type: '',
        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        // const { location } = this.props.history;
        // var { pathname } = location
        // this.props.layoutContentLoad({ 'slug': pathname });
        const { search } = this.props.location;
        var message = '';
        var message_type = '';
        var string_search = search;
        if (search) {
            if (search.indexOf("?") == 0) {
                string_search = string_search.substring(1);
            }

            var getdata = string_search.split("&");
            getdata.forEach(element => {
                var splictContent = element.split("=");
                if (splictContent[0] == 'msg_type') {
                    message_type = splictContent[1];
                }
                if (splictContent[0] == 'msg') {
                    message = splictContent[1];
                }
            });

            this.setState({ message: message, message_type: message_type })
        }



    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            layout_content: props.layout_content
        };
    }
    handleStepChange = () => {
        var step = this.renewal_premium.value;
        if (step) {
            this.props.history.push('/payment-step-1');
        }


    }

    handleBackHistory = () => {
        this.props.history.push('/paydirect-step-2');

    }
    getContentData = (menu_id) => {

        const requestData = {
            menu_id: menu_id,
        }
        this.props.sectionandContent(requestData);
    }

    render() {

        console.log("this layout_content = ", this.props.layout_content);
        const layout_content = this.props.layout_content.content;
        var top_html = '';
        var contactus_message = '';
        var contactus_map = '';
        var addresssocialicon = '';

        if (layout_content) {
            const pagesection = layout_content.pagesection;
        }

        const lang = this.props.lang;

        // <Redirect to='/payment-step-2' />



        return (
            <Fragment>

                <section class="pay-direct mt-5 mb-5">

                    <div class="container">


                        {(this.state.message != '' && this.state.message_type == 'success') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-success alert-dismissible fade show" role="alert">

                                <Htmlcontent data={decodeURI(this.state.message)} />
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                        {(this.state.message != '' && this.state.message_type == 'danger') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                <Htmlcontent data={decodeURI(this.state.message)} />
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}

                        <Stepthreeform handleBackHistory={this.handleBackHistory} />
                    </div>

                </section>
            </Fragment >
        );
    }
}

const mapStateToProps = state => ({
    layout_content: state.contents.common_content,
    utils: state.utils,
    header: state.header,
    contents: state.contents,
    footer: state.footer,
    layout_id: state.utils.layout_id,
    routemenu: state.utils.routemenu,
    setting: state.utils.setting,
    lang: state.utils.lang
})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    layoutContentLoad: (requestData) => dispatch(layoutContentLoad(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Paydirectstepthree));