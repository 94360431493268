import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { layoutContentLoad, getSetting, setPathLayout } from "../../actions/Utils";
import Htmlcontent from '../../components/Htmlcontent';
import BreadCrumbs from '../../components/page/BreadCrumbs';
import { image_url } from '../../config/Api';
import LangData from '../common/LangData';


class Paydirectstepone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            container: {},
            footer: {},
            layout_id: '',
            layout_content: null,

        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { location } = this.props.history;
        var { pathname } = location

        this.props.layoutContentLoad({ 'slug': pathname });

    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            layout_content: props.layout_content
        };
    }
    handleStepChange = () => {
        var step = this.renewal_premium.value;
        if (step) {
            this.props.history.push(step);
        }

        console.log("step data=", step);
    }
    getContentData = (menu_id) => {

        const requestData = {
            menu_id: menu_id,
        }
        this.props.sectionandContent(requestData);
    }

    handleBackHistory = () => {
        this.props.history.push('/');
       
    }

    render() {

        console.log("this layout_content = ", this.props.layout_content);
        const layout_content = this.props.layout_content.content;
        var top_html = '';
        var contactus_message = '';
        var contactus_map = '';
        var addresssocialicon = '';

        if (layout_content) {
            const pagesection = layout_content.pagesection;
        }

        const lang = this.props.lang;
        return (
            <Fragment>




                <div class="container mt-5 mb-5">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="pay-direct-block">
                                <h3>
                                    <LangData keyname="label_pay_direct" />
                                </h3>
                                <p>
                                <LangData keyname="label_no_required_registration" />
                                </p>
                            </div>
                            <form action="">
                                <label for=""><LangData keyname="label_select" /><span>*</span></label>
                                <select ref={(input) => this.renewal_premium = input} className="form-control"
                                    onChange={() => this.handleStepChange()}>
                                    <option value="">Please Select</option>
                                    <option value="paydirect-step-2">Renewal Premium</option>
                                </select>


                                <button class="btn btn-secondary ml-3" onClick={()=>this.handleBackHistory()}><LangData keyname="label_back_home" /></button>
                                
                            </form>

                        </div>
                    </div>
                </div>







            </Fragment >
        );
    }
}

const mapStateToProps = state => ({
    layout_content: state.contents.common_content,
    utils: state.utils,
    header: state.header,
    contents: state.contents,
    footer: state.footer,
    layout_id: state.utils.layout_id,
    routemenu: state.utils.routemenu,
    setting: state.utils.setting,
    lang: state.utils.lang
})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    layoutContentLoad: (requestData) => dispatch(layoutContentLoad(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Paydirectstepone));