import axios from 'axios';
import $ from 'jquery';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { parseString } from 'xml2js';
import { SetMetaData,changeCustomerPassword } from '../../actions/Utils';
import  Header  from './Header';
import InnerHTML from 'dangerously-set-inner-html';


class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state =
    {
      content: {},
      content_loading: true,
      validator: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      message: '',
      message_type: '',
    }
    this.refs = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const metadata = {
      meta_title: 'LIC | Change Passwrod',
      meta_description: '',
      meta_keywords: 'LIC,Change Passwrod',
    }
    this.props.SetMetaData(metadata);
  }



  static getDerivedStateFromProps(props, state) {
    return {
      committe: props.committe
    }
  }

  handleMessageClose = () => {
    this.setState({ message: '', message_type: '' })
  }

  handleSubmit = (event) => {

    const { validator } = this.state;

    event.preventDefault();
    const userFormData = {
      old_password: this.old_password.value,
      password: this.password.value,
      password_confirmation: this.password_confirmation.value,
    }

    const resetValidator = {
      old_password: '',
      password: '',
      password_confirmation: '',
    }
    var validation = {};



    if (userFormData.old_password == '') {
      validation.old_password = 'Old password field is required.';
    }

    if (userFormData.password == '') {
      validation.password = 'New password field is required.';
    }
    if (userFormData.password_confirmation == '') {
      validation.password_confirmation = 'Re-type password field is required.';
    }

    if (userFormData.password && userFormData.password_confirmation && userFormData.password != userFormData.password_confirmation) {
      validation.password_confirmation = 'Password not match';
    }


    if (Object.keys(validation).length) {
      this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

    }
    else {
      var error_validate = {};
      const customer = localStorage.getItem('customer');
      const customer_data = JSON.parse(customer);
      const post_data = {
        'customer_id': customer_data['customer_id'],
        'old_password': userFormData.old_password,
        'password': userFormData.password,
        'password_confirmation': userFormData.password_confirmation
      };
      const headers = {
        'AuthorizationHeader': 'Bearer ' + localStorage.getItem('token')
      }
        this.props.changeCustomerPassword(post_data).then(res => {

          if (res.status == 'success') {
              
              this.setState({ message: res.message, message_type: 'success', validator: { ...resetValidator } });

              $('#formid').trigger("reset");
             
          }
          else {
              this.setState({ message: res.message, message_type: 'danger', validator: { ...resetValidator } })
          }


      })
          .catch(error => {
              if (error.response) {
                  var html = '';
                  if (Object.keys(error.response.data).length) {
                      for (const [key, value] of Object.entries(error.response.data.errors)) {
                          // console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                          html += `${value}` + '</br>';

                          if (typeof validator[key] != "undefined") {
                            error_validate[`${key}`] = `${value}`;
                         }
                      }

                  }
                  // console.log(' error.response.data ', error.response.data);
                  // console.log(' error.response.status ', error.response.status);
                  // console.log('error.response.headers ', error.response.headers);
              } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the 
                  // browser and an instance of
                  // http.ClientRequest in node.js
                  // console.log('error.request ',error.request);
              } else {
                  // Something happened in setting up the request that triggered an Error
                  // console.log('Error', error.message);
              }

              if(Object.keys(error_validate).length) {
                this.setState({validator:{...validation,...error_validate}})
              }
              else {
                this.setState({ message: html, message_type: 'danger', validator: { ...validation } })
              }
              
              

          })

    }
  }

  render() {
    const { validator } = this.state;
    return (
      <Fragment>
        <section className="user-dashboard">

          <div className="container">


            <Header />

            <div className="sign-up">

              
              {/* <div className="row"> */}
             
                {this.state.message != '' && this.state.message_type == 'success' && <div className="col-sm-12" >
                  <div className="alert  alert-success alert-dismissible fade show" role="alert">
                  <InnerHTML html={this.state.message} />
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>}
                {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-sm-12">
                  <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                  <InnerHTML html={this.state.message} />
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>}
                {/* </div> */}
                
                <div className="change-password">
                  <form className="" id="formid">

                  <h3>Change Password</h3>

                    <div className="form-group">

                      <input type="password" ref={(input) => this.old_password = input} className={validator.old_password ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Enter old password" />

                      {validator.old_password && <span className="help-block form-text text-danger">{validator.old_password}</span>}
                    </div>
                    <div className="form-group">

                      <input type="password" ref={(input) => this.password = input} className={validator.password ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Enter new password" />

                      {validator.password && <span className="help-block form-text text-danger">{validator.password}</span>}
                    </div>
                    <div className="form-group">

                      <input type="password" ref={(input) => this.password_confirmation = input} className={validator.password_confirmation ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Re-type password" />

                      {validator.password_confirmation && <span className="help-block form-text text-danger">{validator.password_confirmation}</span>}
                    </div>
                    <input
                      type="submit"
                      value="Change"
                      className="btn btn-primary"
                      onClick={this.handleSubmit}
                    />
                  </form>
                </div>
              </div>
            
          </div>
        </section>
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
})

const mapDispatchToProps = (dispatch) => ({
  SetMetaData: (requestData) => dispatch(SetMetaData(requestData)),
  changeCustomerPassword: (requestData) => dispatch(changeCustomerPassword(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));