import React, { Component, Fragment } from 'react';
import Marquee from 'react-fast-marquee';
import { connect } from 'react-redux';
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
import Cookies from 'js-cookie';
import LangData from '../common/LangData';
// import '../../Styles/Newsbulliten.css';

class NewsBulletin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            home_layout: {
                content: null
            },

        }

    }
    componentDidMount=()=>{
        //console.log("hello ticker");
        /* $('.js-conveyor-3').jConveyorTicker({
            reverse_elm: true
          }); */
    }
    static getDerivedStateFromProps(props, state) {

        return {
            home_layout: props.home_layout,
            lang:props.lang
        }
    }

    render() {
        const content = this.state.home_layout.content;
        const lang = Cookies.get('lang')?Cookies.get('lang'):'bn';
        var section_content = [];
        if (content.page_data) {

            var page_section = content.page_data.section;

            const section_data = page_section.find((row) => {
                if (row.name.toLowerCase() == 'news buletin') {
                    return true;
                }
                else {
                    return false;
                }
            });
            if (section_data) {
                var section_content = section_data.contents;
                
            }
        }

        return (
            <section className="latest-news-section">
                <div className='container'>

                    <div className="d-wrap">

                        <aside className="d-playbox">

                            <div className="d-demo-wrap">

                                <div className="jctkr-label">
                                    <LangData keyname="label_latest_news" />:
                                </div>

                                <div className="js-conveyor-3 jctkr-wrapper jctkr-initialized" ref={el => this.el = el}>
                                        <Marquee 
                                        pauseOnHover={true}
                                        speed={50}
                                        gradient={false}
                                        >
                                            {section_content && section_content.map((row,key) => {
                                                return (
                                                    <Fragment key={key}>

                                                        <p>

                                                            <a href="javascript:void(0)">
                                                                <i className="fa fa-chevron-double-right"></i>
                                                               
                                                                {lang === 'en'&& <Fragment>{row.title}</Fragment>}
                                                                {lang === 'bn'&& <Fragment>{row.bn_title}</Fragment>}
                                                            </a>
                                                            {/* <a href={`${process.env.PUBLIC_URL}`+row.slug}>
                                                                <i className="fa fa-chevron-double-right"></i>
                                                               
                                                                {lang === 'en'&& <Fragment>{row.title}</Fragment>}
                                                                {lang === 'bn'&& <Fragment>{row.bn_title}</Fragment>}
                                                            </a> */}
                                                            {/* {
                                                                row.is_hot_news==1?
                                                                <span className="just_in">Just In</span>
                                                                :''
                                                            } */}
                                                        </p>
                                                    </Fragment>
                                                )
                                            })}
                                        </Marquee>
                                    

                                </div>

                            </div>

                        </aside>

                    </div>
                    </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    headers: state.header,
    setting: state.utils.setting,
    home_layout: state.contents.home_layout,
    lang: state.utils.lang
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsBulletin);