import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import  Header  from './Header';
import { SetMetaData, userpanelDashboard } from '../../actions/Utils';
import Htmlcontent from '../Htmlcontent';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state =
    {
      content: [],
      content_loading: true,
      validator: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      message: '',
      message_type: '',
    }
    this.refs = React.createRef();
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const metadata = {
      meta_title: 'LIC | Dashboard',
      meta_description: '',
      meta_keywords: '',
    }
    this.props.SetMetaData(metadata);

    const customer = localStorage.getItem('customer');

    if (customer) {
      var customer_data = JSON.parse(customer);
      console.log("customer_data =", customer_data);
      const requestData = {
        'customer_id': customer_data['customer_id']
      }

      console.log("requestData =", requestData);
      this.props.userpanelDashboard(requestData).then((data) => {
        this.setState({ content: data })
      })
    }

    const { search } = this.props.location;
    var message = '';
    var message_type = '';
    var string_search = search;
    if (search) {
      if (search.indexOf("?") == 0) {
        string_search = string_search.substring(1);
      }

      var getdata = string_search.split("&");
      getdata.forEach(element => {
        var splictContent = element.split("=");
        if (splictContent[0] == 'msg_type') {
          message_type = splictContent[1];
        }
        if (splictContent[0] == 'msg') {
          message = splictContent[1];
        }
      });

      this.setState({ message: message, message_type: message_type })
    }


  }



  // static getDerivedStateFromProps(props, state) {
  //   return {
  //     committe: props.committe
  //   }
  // }

  handleMessageClose = () => {
    this.setState({ message: '', message_type: '' })
  }



  render() {
    const { validator, content } = this.state;
    console.log("content Hello=", content)
    return (
      <Fragment>
        <section className="user-dashboard">

          <div className="container">


            <Header />

            <div className="user-dashboard-content">

              {(this.state.message != '' && this.state.message_type == 'success') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                <div className="alert  alert-success alert-dismissible fade show" role="alert">

                  <Htmlcontent data={decodeURI(this.state.message)} />
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>}
              {(this.state.message != '' && this.state.message_type == 'danger') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                  <Htmlcontent data={decodeURI(this.state.message)} />
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>}


              <table className="table responsive-table" width="100%">
                <thead>

                  <tr>
                    <td>Serial No</td>
                    <td>Policy Name</td>
                    <td>Premium  Due</td>
                    <td>FUP</td>
                    <td>Last Payment</td>
                    <td>Status</td>
                    <td>Action</td>

                  </tr>
                </thead>
                <tbody>
                  {(content && content.length)? content.map((row, key) => {
                    return (
                      <Fragment key={key}><tr>
                        <td data-label="Serial No">{key + 1}</td>
                        <td data-label="Policy name">{row.policy ? row.policy.name : ''}</td>
                        <td data-label="Premium  Due">200</td>
                        <td data-label="FUP">11/11/2021</td>
                        <td data-label="Last Payment">01/01/1970</td>
                        <td data-label="Status"> NFORCE</td>
                        <td data-label="Action">

                          <Link to={{ pathname: `/dashboard/policyinvoice/${row.core_system_policy_id}` }} className="mr-2">
                            <button type="button" className="btn btn-success">Pay Now</button>
                          </Link>
                          <Link to={{ pathname: `/dashboard/policy-details/${row.id}` }}>
                            <button type="button" className="btn btn-danger">Details</button>
                          </Link>
                        </td>

                      </tr></Fragment>
                    )
                  }):''}


                </tbody>
              </table>
            </div>


          </div>

        </section>
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
})

const mapDispatchToProps = (dispatch) => ({
  SetMetaData: (requestData) => dispatch(SetMetaData(requestData)),
  userpanelDashboard: (requestData) => dispatch(userpanelDashboard(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));