import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getSetting, homeLayoutContent, setPathLayout } from "../actions/Utils";
// import '../App.css';
import HtmlHelmet from '../components/common/HtmlHelmet';
import CarouselSlider from '../components/home/CarouselSlider';
import ProductSlider from '../components/home/ProductSlider';
import LatestNews from '../components/home/LatestNews';

import NewsBulletin from '../components/home/NewsBulletin';
import PremiumCalculator from '../components/home/PremiumCalculator';
import LicMobileApp from '../components/home/LicMobileApp';
import DifferentContent from '../components/home/DifferentContent';
import InsuranceGroup from '../components/home/InsuranceGroup';


class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            footer:{},
            layout_id:'',
        };
    }

    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const {location} = this.props.history;
        var {pathname} = location
        this.props.homeLayoutContent().then((res)=>{})
    }

    setPathLayout = (parms) => {
       this.props.setPathLayout(parms);
    }
    getSetting = async () => {
       let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
            return {
                path: props.utils.path,
                header:props.header,
                container:props.container,
                footer:props.footer,
                layout_id:props.utils.layout_id
             };
      }
    getContentData = (menu_id) => {
      
        const requestData = {
            menu_id:menu_id,
        }
        this.props.sectionandContent(requestData);
    }

    render() {
        var {path} = this.state
        const {header_loading, footer_loading} = this.props.utils;
        let str = path.substr(0,1);
        if(str == '/') {
            path = path.substring(1)
        }
       
        return (
            <Fragment>
             {this.props.meta_data?<HtmlHelmet />:''}
              <CarouselSlider/>
              <NewsBulletin/>
              <ProductSlider/>
              <InsuranceGroup />
              <PremiumCalculator/>
              <DifferentContent />
              <LicMobileApp/>
           </Fragment> 
        );
    }
}

const mapStateToProps = state => ({
    homelayout:state.homelayout,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    setting:state.utils.setting,
    meta_data: state.utils.meta_data,
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    homeLayoutContent: (requestData) => dispatch(homeLayoutContent(requestData)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Home));