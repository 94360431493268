
import React, { Fragment } from 'react';
import Htmlcontent from '../Htmlcontent';

function BranchList(props) {
    return (
        <div class="col-md-4">
            <div class="branch-locator-single">
                
                {props.lang == 'en' && <Fragment> <h3>{props.data.name}</h3></Fragment>}
                {props.lang == 'bn' && <Fragment> <h3>{props.data.bn_name}</h3></Fragment>}

                <p>
                    {props.lang == 'en' && <Fragment> <Htmlcontent data={props.data.address}/> </Fragment>}
                    {props.lang == 'bn' && <Fragment> <Htmlcontent data={props.data.bn_address}/></Fragment>}
                </p>

                <hr />
                
                

                {props.lang == 'en' && <Fragment>{props.data.oparator_first_name && <Fragment>
                    <h4>{props.data.oparator_first_name}</h4>
                    <p>{props.data.designation_first ? <Fragment>
                        {props.data.designation_first.name}
                    </Fragment> : ''}</p>
                </Fragment>} </Fragment>}
                {props.lang == 'bn' && <Fragment>{props.data.bn_oparator_first_name && <Fragment>
                    <h4>{props.data.bn_oparator_first_name}</h4>
                    <p>{props.data.designation_first ? <Fragment>
                        {props.data.designation_first.bn_name}
                    </Fragment> : ''}</p>
                </Fragment>}</Fragment>}



             


                {props.lang == 'en' && <Fragment>
                    
                {props.data.operator_second_name && <Fragment>
                    <div class="separator"></div>
                    <h4>{props.data.operator_second_name}</h4>
                    <p>{props.data.designation_second ? <Fragment>
                        {props.data.designation_second.name}
                    </Fragment> : ''}</p>
                </Fragment>}

                    </Fragment>}
                {props.lang == 'bn' && <Fragment>
                    
                {props.data.bn_operator_second_name && <Fragment>
                    <div class="separator"></div>
                    <h4>{props.data.bn_operator_second_name}</h4>
                    <p>{props.data.designation_second ? <Fragment>
                        {props.data.designation_second.bn_name}
                    </Fragment> : ''}</p>
                </Fragment>}
                    </Fragment>}

                {props.lang == 'en' && <Fragment>
                    
                {props.data.operator_third_name && <Fragment>
                    <div class="separator"></div>
                    <h4>{props.data.operator_third_name}</h4>
                    <p>{props.data.designation_third ? <Fragment>
                        {props.data.designation_third.name}
                    </Fragment> : ''}</p>
                </Fragment>}

                    </Fragment>}
                {props.lang == 'bn' && <Fragment>
                    
                {props.data.bn_operator_third_name && <Fragment>
                    <div class="separator"></div>
                    <h4>{props.data.bn_operator_third_name}</h4>
                    <p>{props.data.designation_third ? <Fragment>
                        {props.data.designation_third.bn_name}
                    </Fragment> : ''}</p>
                </Fragment>}
                    </Fragment>}

                
            </div>
        </div>
    );
}

export default BranchList;
