import $ from "jquery";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  getReturnCalculations,
  getReturnPoliciesBasedOnAge,
  getReturnPremiumListBasedOnTerm,
  getReturnTermsBasedOnPolicy,
  sendContactus,
  setLayout,
  setPath,
  setPathLayout,
} from "../../actions/Utils";

import "react-datepicker/dist/react-datepicker.css";
import LangData from "../common/LangData";
import { DateInput } from "./Dateinput";

class ReturncalCulatorform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validator: {
        dob: "",
        policy: "",
        term: "",
        premium: "",
        sum_assured: "",
        critical_illness: "",
        critical_sum: "",
      },
      message: "",
      message_type: "",
      policy: {},
      terms: {},
      premiums: {},
      calculated_result: {},
      modedata: {},
      styleinfo: {
        accidental: "none",
        licterm: "block",
        parentage: "none",
        critical: "none",
        critical_sum: "none",
      },
      calculation: {},
      criticalMax: 2000000,
    };
    this.refs = React.createRef();
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      lang: props.lang,
    };
  }

  handleMessageClose = () => {
    this.setState({ message: "", message_type: "" });
  };

  handleDateChange(date) {
    this.setState({
      startDate: date,
    });
  }

  handleSubmit = () => {
    var styleinfo = this.state.styleinfo;
    const userFormData = {
      dob: $("#dateofbirth").val(),
      policy_id: this.policy.value,
      term: styleinfo.licterm == "block" ? this.term.value : 0,
      premium: this.premium.value,
    };

    const resetValidator = {
      dob: "",
      policy: "",
      term: "",
      premium: "",
      sum_assured: "",
    };
    var validation = {};
    console.log("userFormData: ", userFormData);
    if (userFormData.dob == "") {
      validation.dob = "This field is required.";
    }

    if (userFormData.policy_id == "") {
      validation.policy = "This field is required.";
    }

    if (userFormData.term == "" && styleinfo.licterm == "block") {
      validation.term = "This field is required.";
    }
    if (userFormData.premium == "" && styleinfo.licterm == "block") {
      validation.premium = "This field is required.";
    }
    if (Object.keys(validation).length) {
      this.setState({
        validator: { ...resetValidator, ...validation },
        message: "",
      });
    } else {
      this.props.getCalculations(userFormData).then((res) => {
        $("#dateofbirth").val("");

        let policy_name = "";
        for (var item in this.state.policy) {
          if (item == res.plan.policy_id) {
            console.log(this.state.policy[item]);
            policy_name = this.state.policy[item];
          }
        }
        this.setState({
          calculation: {
            ...res.plan,
            policy_name: policy_name,
          },
          message: "",
          message_type: "success",
          validator: { ...resetValidator },
        });
      });
    }
  };

  getCalculatedResultForCriticalIlluness = () => {
    const userFormData = {
      dob: $("#dateofbirth").val(),
      policy_id: this.policy.value,
      term: this.term.value,
      premium: this.premium.value,
    };
    this.props.getCalculations(userFormData).then((res) => {
      if (res.plan) {
        this.setState({
          calculated_result: {
            ...res.plan,
          },
        });
      }
    });
  };
  handleValidatonRest = () => {
    const resetValidator = {
      dob: "",
      policy_id: "",
      term: "",
      premium: "",
      sum_assured: "",
      parentage: "",
    };
    this.setState({ validator: { ...resetValidator } });
  };
  handlePoliciesBasedOnAge = () => {
    this.handleValidatonRest();
    var dob = $("#dateofbirth").val();

    if (dob) {
      this.props.getPoliciesBasedOnAge({ dob: dob }).then(({ policies }) => {
        this.setState({ policy: policies });
      });
    }
  };
  handleTermsBasedOnPolicy = () => {
    this.handleValidatonRest();
    var dob = $("#dateofbirth").val();
    var policy = this.policy.value;
    if (dob && policy) {
      this.props
        .getTermsBasedOnPolicy({ dob: dob, policy_id: policy })
        .then(({ terms }) => {
          this.setState({ terms: terms });
        });
      this.handleOptionShowHide();
    }
  };
  handlePremiumListBasedOnTerm = () => {
    this.handleValidatonRest();
    var dob = $("#dateofbirth").val();
    var policy = this.policy.value;
    var term = this.term.value;
    if (dob && policy & term) {
      this.props
        .getReturnPremiumList({
          dob: dob,
          policy_id: policy,
          term: term,
        })
        .then(({ premiums }) => {
          this.setState({ premiums: premiums });
        });
      this.handleOptionShowHide();
    }
  };

  handleOptionShowHide = () => {
    var policy = this.policy.value;
    let conditionalData = {
      accidental: "block",
      licterm: "block",
      parentage: "none",
      critical: "none",
      critical_sum: "none",
    };
    switch (policy) {
      case "814":
        conditionalData.critical = "block";
        break;
      case "815":
        conditionalData.critical = "none";
        break;
      default:
        conditionalData.parentage = "none";
        conditionalData.licterm = "block";
    }

    this.setState({
      styleinfo: {
        ...this.state.styleinfo,
        ...conditionalData,
      },
    });
  };

  render() {
    const contents = this.props.data.content;
    const { validator, policy, terms, premiums, styleinfo, calculation } =
      this.state;
    const data_loop = [];
    var cumalitive_total = 0;
    var total_40 = 0;
    var total_50 = 0;
    let maturity_benefit =
      calculation.policy_id === 814
        ? calculation.sum_assured
        : calculation.sum_assured * 0.5;

    if (calculation.policy_id) {
      for (let i = 1; i <= parseInt(calculation.terms); i++) {
        total_40 += Math.ceil(calculation.sum_assured * 0.04);
        total_50 += Math.ceil(calculation.sum_assured * 0.05);

        cumalitive_total = calculation.premium_amount * 12 * i;
        data_loop.push(cumalitive_total);
      }
    }

    const policy_data = Object.entries(policy);
    const terms_data = Object.entries(terms);
    const premiums_data = Object.entries(premiums);
    const lang = this.props.lang;
    return (
      <Fragment>
        <div className="contact-from">
          {lang === "en" && (
            <h1>{contents ? contents.short_description : ""}</h1>
          )}
          {lang === "bn" && (
            <h1>{contents ? contents.bn_short_description : ""}</h1>
          )}

          <div className="contact-main">
            <div className="row">
              {this.state.message != "" &&
                this.state.message_type == "success" && (
                  <div className="col-sm-12">
                    <div
                      className="alert  alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      {this.state.message}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => this.handleMessageClose()}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                )}
              {this.state.message != "" &&
                this.state.message_type == "danger" && (
                  <div className="col-sm-12">
                    <div
                      className="alert  alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      {this.state.message}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => this.handleMessageClose()}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                )}
            </div>

            <div className="row">
              <div className="col-md-7">
                <form>
                  <div className="form-group">
                    <label>
                      <LangData keyname="label_premium_calculator_dob" />
                      <span>*</span>
                    </label>

                    <DateInput
                      handlePoliciesBasedOnAge={() =>
                        this.handlePoliciesBasedOnAge()
                      }
                      id="dateofbirth"
                    />

                    {validator.dob && (
                      <span className="help-block form-text text-danger">
                        {validator.dob}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      <LangData keyname="label_premium_calculator_plan_name" />
                      <span>*</span>
                    </label>
                    <select
                      ref={(input) => (this.policy = input)}
                      className={
                        validator.policy
                          ? "form-control from-style input-error"
                          : "form-control from-style"
                      }
                      onChange={() => this.handleTermsBasedOnPolicy()}
                    >
                      <option value="">Select Policy</option>
                      {policy_data.length && (
                        <Fragment>
                          {policy_data.map((row, key) => {
                            return (
                              <option value={row[0]} key={key}>
                                {row[1]}
                              </option>
                            );
                          })}
                        </Fragment>
                      )}
                    </select>

                    {validator.policy && (
                      <span className="help-block form-text text-danger">
                        {validator.policy}
                      </span>
                    )}
                  </div>
                  <div
                    className="form-group"
                    style={{ display: styleinfo.licterm }}
                  >
                    <label>
                      <LangData keyname="label_premium_calculator_term" />
                      <span>*</span>
                    </label>

                    <select
                      ref={(input) => (this.term = input)}
                      className={
                        validator.term
                          ? "form-control from-style input-error"
                          : "form-control from-style"
                      }
                      onChange={() => this.handlePremiumListBasedOnTerm()}
                    >
                      <option value="">Select terms</option>

                      {terms_data.length && (
                        <Fragment>
                          {terms_data.map((row, key) => {
                            return (
                              <option value={row[1]} key={key}>
                                {row[1]}
                              </option>
                            );
                          })}
                        </Fragment>
                      )}
                    </select>
                    {validator.term && (
                      <span className="help-block form-text text-danger">
                        {validator.term}
                      </span>
                    )}
                  </div>
                  <div
                    className="form-group"
                    style={{ display: styleinfo.licterm }}
                  >
                    <label>
                      <LangData keyname="label_return_calculator_premium" />
                      <span>*</span>
                    </label>

                    <select
                      ref={(input) => (this.premium = input)}
                      className={
                        validator.term
                          ? "form-control from-style input-error"
                          : "form-control from-style"
                      }
                    >
                      <option value="">Select premium</option>

                      {premiums_data.length && (
                        <Fragment>
                          {premiums_data.map((row, key) => {
                            return (
                              <option value={row[1]} key={key}>
                                {row[1]}
                              </option>
                            );
                          })}
                        </Fragment>
                      )}
                    </select>
                    {validator.term && (
                      <span className="help-block form-text text-danger">
                        {validator.term}
                      </span>
                    )}
                  </div>
                </form>

                <div className="submit">
                  <input
                    type="button"
                    value={lang === "bn" ? "ক্যালকুলেট" : "Calculate"}
                    className="btn submit-button"
                    name="submit"
                    onClick={() => this.handleSubmit()}
                  />
                </div>
              </div>
              <div className="col-md-5">
                {calculation.policy_id && (
                  <div className="calculated-result">
                    <p>
                      <span className="label">
                        <LangData keyname="label_premium_calculator_plan_name" />
                        :{" "}
                      </span>{" "}
                      {calculation.policy_name}
                    </p>
                    <p>
                      <span className="label">
                        {" "}
                        <LangData keyname="label_premium_calculator_customer_age" />
                        :
                      </span>{" "}
                      {calculation.age}
                    </p>
                    <p>
                      <span className="label">
                        <LangData keyname="label_premium_calculator_monthly_premium" />
                        :{" "}
                      </span>
                      {calculation.premium_amount}
                    </p>
                    <p>
                      <span className="label">
                        <LangData keyname="label_premium_calculator_term" />:
                      </span>{" "}
                      {calculation.terms}
                    </p>
                    <p>
                      <span className="label">
                        <LangData keyname="label_premium_calculator_mode" />:
                      </span>{" "}
                      Monthly
                    </p>
                    <p>
                      <span className="label">
                        <LangData keyname="label_premium_calculator_sum_assured" />
                        :
                      </span>{" "}
                      {calculation.sum_assured}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* this for LIC Jibon Swapno */}
        {calculation.policy_id && (
          <div className="cumalative_data mt-4">
            <table className="table">
              <tr>
                <td>Year</td>
                <td>Monthly Premium Payable (Tk)</td>
                <td>Yearly Cumulative paid premium (Tk)</td>
                {this.policy.value === "815" && <td>Survival Benefit (Tk)</td>}
                <td>Payable on Death before Maturity date (Tk)</td>
                <td>Maturity Benefit (Tk)</td>
                <td>Maturity payable if BDT 40 Bonus is declared (Tk)</td>
                <td>Maturity payable if BDT 50 Bonus is declared (Tk)</td>
              </tr>

              {data_loop.length &&
                data_loop.map((row, key) => {
                  return (
                    <tr id={key}>
                      <td>{key + 1}</td>
                      <td>{calculation.premium_amount}</td>
                      <td>{row}</td>
                      {this.policy.value === "815" &&
                        key !== data_loop.length && (
                          <td
                            // rowSpan={data_loop.length}
                            style={{ verticalAlign: "middle" }}
                          >
                            {data_loop.length / 3 === key + 1 ||
                            (data_loop.length / 3) * 2 === key + 1
                              ? Math.ceil(calculation.sum_assured * 0.25)
                              : ""}
                          </td>
                        )}
                      {/* {key == 0 && this.policy.value === "814" && (
                        <td
                          rowSpan={data_loop.length}
                          style={{ verticalAlign: "middle" }}
                        >
                          {Math.ceil(calculation.sum_assured * 0.5)}
                        </td>
                      )} */}
                      {key === 0 && (
                        <td
                          rowSpan={data_loop.length}
                          style={{ verticalAlign: "middle" }}
                        >
                          {Math.ceil(calculation.sum_assured)}+Bonus
                        </td>
                      )}
                      {key == 0 && <td rowSpan={data_loop.length}></td>}
                      <td>{Math.ceil(calculation.sum_assured * 0.04)}</td>
                      <td>{Math.ceil(calculation.sum_assured * 0.05)}</td>
                    </tr>
                  );
                })}
              <tr>
                <td colSpan={this.policy.value === "814" ? 4 : 5}>
                  On maturity date at the end of {calculation.terms} year
                </td>

                <td>
                  {Math.ceil(maturity_benefit)}
                  +Bonus
                </td>

                <td>
                  {Math.ceil(maturity_benefit)} +{" "}
                  {Math.ceil(parseInt(total_40))}
                  {" = "}
                  {Math.ceil(maturity_benefit) + total_40}
                </td>
                <td>
                  {Math.ceil(maturity_benefit)} +{" "}
                  {Math.ceil(parseInt(total_50))}
                  {" = "}
                  {Math.ceil(maturity_benefit) + Math.ceil(parseInt(total_50))}
                </td>
              </tr>
            </table>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.utils.lang,
});
const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => {
    dispatch(setPath(requestData));
  },
  setLayout: (requestData) => {
    dispatch(setLayout(requestData));
  },
  setPathLayout: (requestData) => {
    dispatch(setPathLayout(requestData));
  },
  sendContactus: (requestData) => {
    dispatch(sendContactus(requestData));
  },
  getPoliciesBasedOnAge: (requestData) =>
    dispatch(getReturnPoliciesBasedOnAge(requestData)),
  getTermsBasedOnPolicy: (requestData) =>
    dispatch(getReturnTermsBasedOnPolicy(requestData)),
  getReturnPremiumList: (requestData) =>
    dispatch(getReturnPremiumListBasedOnTerm(requestData)),
  getCalculations: (requestData) =>
    dispatch(getReturnCalculations(requestData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturncalCulatorform);
