import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";


const MultiMenusMobile = ({ menus, lang, menu_open,handleMenu }) => {

    // console.log("menus=",menus);

    const [activeMenus, setActiveMenus] = useState([]);

    const handleMenuClick = data => {
        console.log(data);
    };

    const handleArrowClick = (menuName,isParents) => {

        console.log("before menuName=",activeMenus);
        let newActiveMenus = [...activeMenus];

        if (newActiveMenus.includes(menuName)) {
            var index = newActiveMenus.indexOf(menuName);
            if (index > -1) {
                newActiveMenus.splice(index, 1);
            }
        } else {
            newActiveMenus.push(menuName);
        }
        console.log("after menuName=",newActiveMenus);
        setActiveMenus(newActiveMenus);

        if(!isParents) {
            handleMenu(false);
            setActiveMenus([]);
        }
    };

    const ListMenu = ({ dept, data, hasSubMenu, menuName, menuIndex }) => (
        <li className={`${dept == 1 && hasSubMenu ? 'second-label ' : ''}${hasSubMenu ? 'menu-dropdown-icon ' : ''} ${(hasSubMenu && dept == 2) ? 'third-label ' : ''}${(hasSubMenu && dept == 3) ? 'fourth-label ' : ''}`}>
            <Fragment key={menuIndex} >
              
                {data.sub_nav.length > 0 ? <Link to={''} onClick={() => handleArrowClick(menuName,true)} toggle={activeMenus.includes(menuName)}>
                    {lang === 'en' && <Fragment>{data.title}</Fragment>}
                    {lang === 'bn' && <Fragment>{data.bn_title}</Fragment>}

                </Link> : ''}
                {data.sub_nav.length == 0 ?
                    <Fragment>
                        {data.link_type == 1 && <Link to={{ pathname: `${data.url}` }} onClick={() => handleArrowClick(menuName,false)} toggle={activeMenus.includes(menuName)} on>
                            {lang === 'en' && <Fragment>{data.title}</Fragment>}
                            {lang === 'bn' && <Fragment>{data.bn_title}</Fragment>}
                        </Link>}
                        {data.link_type == 0 && <Link to={{ pathname: `${data.url}` }} target={data.url_target ? data.url_target : '_blank'}>{lang === 'en' && <Fragment>{data.title}</Fragment>}
                            {lang === 'bn' && <Fragment>{data.bn_title}</Fragment>}

                        </Link>}
                    </Fragment> : ''}

            </Fragment>
            {hasSubMenu && (
                <SubMenu
                    dept={dept}
                    data={data.sub_nav}
                    toggle={activeMenus.includes(menuName)}
                    menuIndex={menuIndex}
                />
            )}
        </li>
    );

    const SubMenu = ({ dept, data, toggle, menuIndex }) => {
       // console.log('toggle =', toggle, 'dept=', dept, 'data=', data);
        if (!toggle) {
            return null;
        }

        dept = dept + 1;

        return (
            <ul style={{display:'block'}}>
                {data.map((menu, index) => {
                    const menuName = `parent-submenu-${dept}-${menuIndex}-${index}`;
                    return (
                        <ListMenu
                            dept={dept}
                            data={menu}
                            hasSubMenu={menu.sub_nav.length ? menu.sub_nav : false}
                            menuName={menuName}
                            key={menuName}
                            menuIndex={index}
                        />
                    );
                })}
            </ul>
        );
    };

    return (



        <ul className={`clearfix headermenu ${menu_open?'show-on-mobile':''}`}>
            {menus.map((menu, index) => {
                const dept = 1;
                const menuName = `parent-menu-${dept}-${index}`;

                return (
                    <ListMenu
                        dept={dept}
                        data={menu}
                        hasSubMenu={menu.sub_nav.length ? menu.sub_nav : false}
                        menuName={menuName}
                        key={menuName}
                        menuIndex={index}
                    />
                );
            })}
        </ul>
    );
};

export default MultiMenusMobile;
