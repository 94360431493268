import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import  Header from './Header';
import { SetMetaData, getPolicyInvoice } from '../../actions/Utils';
import axiosInstance,{version} from '../../config/Api';


class PaymentInvoice extends Component {
  constructor(props) {
    super(props);
    this.state =
    {
      content: null,
      content_loading: true,
      validator: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      message: '',
      message_type: '',
    }
    this.refs = React.createRef();
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const metadata = {
      meta_title: 'LIC | Policy Invoice',
      meta_description: '',
      meta_keywords: '',
    }
    this.props.SetMetaData(metadata);

    const customer = localStorage.getItem('customer');
    const { params } = this.props.match;
    console.log("match data =",this.props);
    if (customer) {
      var customer_data = JSON.parse(customer);
      console.log("customer_data =", customer_data);
      const requestData = {
        'customer_id': customer_data['customer_id'],
        'policyid': params.id,
      }

      console.log("requestData =", requestData);
      this.props.getPolicyInvoice(requestData).then((data) => {
        this.setState({ content: data })
      })
    }


  }

  handlePayment = () => {
    const content = this.state.content;
    if(content) {
      var payres = content;
      // payres['premium_amount'] = userFormData['premium_amount'];
      payres['cus_code'] = 'pInv'; // pd = Payment invoice
      this.handlePayOnline(payres);
    }
   
  }

  
  handlePayOnline = (policycustomer) => {

    axiosInstance.post(version + 'pay_online', { params: policycustomer }).then(({ data }) => {
        console.log(" hanldePayonline =", data);
        if (data.status == 'SUCCESS' && data.GatewayPageURL) {
            window.location.href = data.GatewayPageURL;
        }
        else {
            this.setState({
                message: 'Sorry, System did not payment successfully. Please contact with your contact person.', message_type: 'danger',
            })
        }

    });
}



  // static getDerivedStateFromProps(props, state) {
  //   return {
  //     committe: props.committe
  //   }
  // }

  handleMessageClose = () => {
    this.setState({ message: '', message_type: '' })
  }



  render() {
    const { validator, content } = this.state;
    console.log("content Hello=", content)
    return (
      <Fragment>
        <section className="user-dashboard">

          <div className="container">


            <Header />

            <div className="user-dashboard-content">

              <table className="table mt-5 responsive-table payment-invoice" width="100%">
                <thead>

                  <tr>
                    <td>Name</td>
                    <td>Policy Number</td>
                    <td>Due From </td>
                    <td>Due To</td>
                    <td>Premium Amount</td>

                  </tr>
                </thead>
                <tbody>
                  {content &&<Fragment>
                  <tr>
                    <td>{content.policy_holder_name}</td>
                    <td>{content.core_system_policy_id}</td>
                    <td>05/10/2021 </td>
                    <td>05/11/2021 </td>
                    <td>{content.premium_amount}</td>

                  </tr>
                  <tr>
                    <td colSpan={2} className="text-right">Total Instelment : 1</td>
                    <td colSpan={2} className="text-right">Total Premium:</td>
                    <td>{content.premium_amount}</td>

                  </tr>
                  <tr>
                    <td colSpan={4} className="text-right">Total:</td>
                    
                    <td>{content.premium_amount}</td>

                  </tr>
                  <tr>
                    <td colSpan={4} className="text-right">Payable Amount:</td>
                    
                    <td>{content.premium_amount}</td>

                  </tr>
                  <tr>
                    <td colSpan={3}>Converting Charges Applicable Approximately 2.5% of Total Amount </td>
                    
                    <td colSpan={2}> 
                      <button type="button" className="btn btn-success mr-1" onClick={()=>this.handlePayment()}>Pay Now</button>
                          <Link to={{ pathname: `/dashboard/` }}>
                            <button type="button" className="btn btn-warning">Cancle</button>
                          </Link>
                      </td>

                  </tr>

                  </Fragment>}
                </tbody>
              </table>
            </div>


          </div>

        </section>
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
})

const mapDispatchToProps = (dispatch) => ({
  SetMetaData: (requestData) => dispatch(SetMetaData(requestData)),
  getPolicyInvoice: (requestData) => dispatch(getPolicyInvoice(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentInvoice));