import InnerHTML from 'dangerously-set-inner-html';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
import { image_url } from '../../config/Api';
import NoImage from '../../img/no_image.jpeg';

class LatestNews extends Component {

  constructor(props) {
    super(props);
    this.state = {
      home_layout: {
        content: null
      },

    }

  }

  static getDerivedStateFromProps(props, state) {

    return {
      home_layout: props.home_layout,
      lang:props.lang
    }
  }
  render() {
    const content = this.state.home_layout.content;
    var section_data = {};
    var section_content = {
      news: [],
      event: [],
    };
    if (content.page_data) {

      var page_section = content.page_data.section;

      section_data = page_section.find((row) => {
        if (row.name.toLowerCase() == 'latest news event') {
          return true;
        }
        else {
          return false;
        }
      });
      if (section_data) {
        var section_content = section_data.contents;

      }
    }
    return (
      section_content.event.length>0 ? 
      <Fragment>
        <section className="upcomming-event">

        <div className="container">

          <div className="section-title-one">

              <h1><span>Events</span></h1>

          </div>

          <div className="row">
            {section_content.event.map((event_row, ekey) => {
                return (
                  <Fragment key={ekey}>
                    <div className="col-md-4">

                      <div className="our-latest-news-single">

                        <img src={event_row.image? `${image_url}${event_row.image}` : NoImage} />

                        <div className="news-date">

                          <h3>{event_row.publish_date}</h3>

                        </div>

                        <div className="our-latest-news-content">

                          <h6>{event_row.category}</h6>

                        <h3>
                          <Link to={{
                              pathname: `${process.env.PUBLIC_URL}${event_row.slug}`
                            }}>
                              {event_row.title.length >100&&<Fragment>{event_row.title.substring(0,100)}...</Fragment>}
                              {event_row.title.length <100&&<Fragment>{event_row.title.substring(0,100)}</Fragment>}
                            
                              </Link>

                          </h3>

                        
                          <p>{event_row.short_description.substring(0,250)}</p>

                        </div>

                      </div>

                    </div>
                  </Fragment>
                )
              })
            }

            {
              Object.keys(section_data).length>0 &&section_data.landing_page_url.length >0 ?
              <Link to={{pathname:`${process.env.PUBLIC_URL}`+section_data.landing_page_url}} className="btn-primary">See all Events</Link>
              :
              <Link to={{pathname:`${process.env.PUBLIC_URL}`}} className="btn-primary">See all Events</Link>
            }
          
          </div>
        </div>
      </section>
    </Fragment>
    :
    ''
    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  home_layout: state.contents.home_layout,
  lang: state.utils.lang
})

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => { dispatch(setPath(requestData)) },
  setLayout: (requestData) => { dispatch(setLayout(requestData)) },
  setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(LatestNews);