import axios from 'axios';
import * as crypto from 'crypto-js';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { parseString } from 'xml2js';
import { casInstance, caversion, encryption_salt, processMaker } from '../config/Api';
import { SetMetaData } from '../actions/Utils'
import { resetUserPassword } from '../actions/Users';
import LangData from '../components/common/LangData';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state =
    {
      content: {},
      content_loading: true,
      validator: {
        newPassword: '',
        confirmPassword: '',
      },
      message: '',
      message_type: '',
    }
    this.refs = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {

    const metadata = {
      meta_title: 'LIC | Reset passwrod',
      meta_description: '',
      meta_keywords: 'LIC,Reset passwrod',
    }
    this.props.SetMetaData(metadata);


    var forgot_user_cookies_header_user = window.btoa("ForgotUserName" + encryption_salt).toString();
    var cookies_forgot_user = Cookies.get(forgot_user_cookies_header_user);

    var forgot_user_otp_varrified = window.btoa("ForgotUserIDOTPVarrified" + encryption_salt).toString();
    var varified_otp = Cookies.get(forgot_user_otp_varrified);
    if (varified_otp) {

      var varified_otp_dycrypt = crypto.AES.decrypt(varified_otp, encryption_salt);
      varified_otp_dycrypt = varified_otp_dycrypt.toString(crypto.enc.Utf8);
    }

    if (!cookies_forgot_user || !varified_otp || varified_otp_dycrypt != 1) {
      const { history } = this.props;
      history.push(`${process.env.PUBLIC_URL}/forgot-password`);
    }
  }



  handleMessageClose = () => {
    this.setState({ message: '', message_type: '' })
  }

  handleSubmit = (event) => {


    event.preventDefault();
    const userFormData = {
      newPassword: this.newPassword.value,
      confirmPassword: this.confirmPassword.value,
    }
    const resetValidator = {
      newPassword: '',
      confirmPassword: '',
    }
    var validation = {};
    if (userFormData.newPassword == '') {
      validation.newPassword = 'New password field is required.';
    }
    if (userFormData.confirmPassword == '') {
      validation.confirmPassword = 'Confirm password field is required.';
    }

    if (userFormData.newPassword && userFormData.confirmPassword && userFormData.newPassword != userFormData.confirmPassword) {
      validation.confirmPassword = 'Password not match';
    }


    if (Object.keys(validation).length) {
      this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

    }
    else {


      var user_cookie_name = window.btoa("ForgotUserName" + encryption_salt).toString();
      var cookie_user_id = Cookies.get(user_cookie_name);
      var user_id_dycrypt = crypto.AES.decrypt(cookie_user_id, encryption_salt);
      user_id_dycrypt = user_id_dycrypt.toString(crypto.enc.Utf8);

      const post_data = {
        'password': userFormData.newPassword,
        'password_confirmation': userFormData.confirmPassword,
        'user_name': user_id_dycrypt
      };

    
      this.props.resetUserPassword(post_data).then((data) => {

        if(data.status == 'success') {
          var user_cookie_name = window.btoa("ForgotUserName" + encryption_salt).toString();
          Cookies.remove(user_cookie_name);
  
          var varify_token_name = window.btoa("ForgotUserIDOTPVarrified" + encryption_salt).toString();
          Cookies.remove(varify_token_name);
          const { history } = this.props;
          history.push(`${process.env.PUBLIC_URL}/login`);

        }
        else {
          this.setState({ message:data.message, message_type: 'danger'})
        }

       
      }).catch(error => {

        if (error.response) {
          var html = '';
          if (Object.keys(error.response.data).length) {

            for (const [key, value] of Object.entries(error.response.data)) {
              //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
              html += `${value}`;
            }

          }

        }
        if (html) {
          // this.setState({ message: error.response.data.message, message_type: 'danger', validator: { ...resetValidator } })
          this.setState({ message: 'Something wrong! please try again... ', message_type: 'danger', validator: { ...resetValidator } })
        }

      });
    }
  }

  render() {
    const { validator } = this.state;
    const lang = this.props.lang;
    return (
      <Fragment>
        <section className="sign-up">
          <div className="container">


            <div className="sign-in-form">
              <form className="">
                <h3><LangData keyname="label_reset_password" /></h3>


                {this.state.message != '' && this.state.message_type == 'success' && <div className="col-sm-12" >
                  <div className="alert  alert-success alert-dismissible fade show" role="alert">
                    {this.state.message}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>}
                {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-sm-12">
                  <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                    {this.state.message}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>}


                <div className="form-group">
                  <label><LangData keyname="label_new_password" /><span>*</span></label>
                  <input type="password" ref={(input) => this.newPassword = input} className={validator.newPassword ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Password" />

                  {validator.newPassword && <span className="help-block form-text text-danger">{validator.newPassword}</span>}
                </div>
                <div className="form-group">
                  <label><LangData keyname="label_confirm_password" /><span>*</span></label>
                  <input type="password" ref={(input) => this.confirmPassword = input} className={validator.confirmPassword ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Password" />

                  {validator.confirmPassword && <span className="help-block form-text text-danger">{validator.confirmPassword}</span>}
                </div>
                <input
                  type="submit"
                  value={lang == 'bn' ? 'দাখিল করুন' : 'Submit'}
                  className="btn btn-primary"
                  onClick={this.handleSubmit}
                />
              </form>
            </div>
          </div>
        </section>
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  lang: state.utils.lang
})

const mapDispatchToProps = (dispatch) => ({
  SetMetaData: (requestData) => dispatch(SetMetaData(requestData)),
  resetUserPassword: (requestData) => dispatch(resetUserPassword(requestData))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));