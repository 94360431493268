import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { layoutContentLoad, getSetting, setPathLayout } from "../../actions/Utils";
import Policyform from './Policyform';
import Htmlcontent from '../../components/Htmlcontent';
import BreadCrumbs from '../../components/page/BreadCrumbs';
import { image_url } from '../../config/Api';
import LangData from '../common/LangData';


class PolicyBuy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            container: {},
            footer: {},
            layout_id: '',
            layout_content: null,
        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { location } = this.props.history;
        var { pathname } = location

        this.props.layoutContentLoad({ 'slug': pathname });

    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            layout_content: props.layout_content,
            lang:props.lang
        };
    }
    getContentData = (menu_id) => {

        const requestData = {
            menu_id: menu_id,
        }
        this.props.sectionandContent(requestData);
    }

    render() {

        console.log("this layout_content = ", this.props.layout_content);
        const layout_content = this.props.layout_content.content;
        var top_html = '';
        var contactus_message = '';
        var contactus_map = '';
        var addresssocialicon = '';

        if (layout_content) {
            const pagesection = layout_content.pagesection;
        }

        const lang = this.props.lang;
        return (
            <Fragment>



                {/* {layout_content.image && <section className="innerpage-header">

                    <img src={`${image_url}${layout_content.image}`} />

                    <div className="container">

                        <div className="innerpage-header-content">

                            <h3>

                             

                                {lang === 'en' && <Fragment>{layout_content.title}</Fragment>}
                                {lang === 'bn' && <Fragment>{layout_content.bn_title}</Fragment>}

                            </h3>

                            <h1>
                                <LangData keyname="lable_breadcrumbs_lic" />
                            </h1>

                        </div>

                    </div>

                </section>} */}


                {layout_content.bread_cumbs && <BreadCrumbs bread_curmbs={layout_content.bread_cumbs} />}


                <section class="policy-buy-page">

                    <div class="container">

                        <div class="service-tabs">
                            <Policyform lang={lang}/>
                        </div>

                    </div>

                </section>


            </Fragment >
        );
    }
}

const mapStateToProps = state => ({
    layout_content: state.contents.common_content,
    utils: state.utils,
    header: state.header,
    contents: state.contents,
    footer: state.footer,
    layout_id: state.utils.layout_id,
    routemenu: state.utils.routemenu,
    setting: state.utils.setting,
    lang:state.utils.lang
})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    layoutContentLoad: (requestData) => dispatch(layoutContentLoad(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PolicyBuy));