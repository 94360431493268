import InnerHTML from 'dangerously-set-inner-html';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
import { image_url } from '../../config/Api';
import Cookies from 'js-cookie';
// import '../../Styles/LicMobileApp.css';

class LicMobileApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      home_layout: {
        content: null
      },

    }

  }

  // componentDidMount = () => {



  // }

  static getDerivedStateFromProps(props, state) {

    return {
      home_layout: props.home_layout,
      lang:props.lang
    }
  }

  render() {
    const content = this.state.home_layout.content;
    var premiumcalculator = [];
    var section_data = [];
    const lang = Cookies.get('lang')?Cookies.get('lang'):'bn';
    if (content.page_data) {

      var page_section = content.page_data.section;

      section_data = page_section.find((row) => {
        if (row.name.toLowerCase() == 'lic mobile app') {
          return true;
        }
        else {
          return false;
        }
      });
      if (section_data) {
        premiumcalculator = section_data.contents;

      }
    }

    console.log(" premiumcalculator =", premiumcalculator);

    return (
      <section className="lic-mobile-app-section">
      {premiumcalculator.length > 0 ? <Fragment>
        {(lang === 'en'&& premiumcalculator[0] != null) && <InnerHTML html={premiumcalculator[0]['description']} />}
        {(lang === 'bn' && premiumcalculator[0] != null) && <InnerHTML html={premiumcalculator[0]['bn_description']} />}
        
        </Fragment>
        :
        ''}
        </section>
     
      );
  }
}

const mapStateToProps = (state) => ({
  headers: state.header,
  setting: state.utils.setting,
  home_layout: state.contents.home_layout,
  lang: state.utils.lang
})

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => { dispatch(setPath(requestData)) },
  setLayout: (requestData) => { dispatch(setLayout(requestData)) },
  setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(LicMobileApp);