import InnerHTML from 'dangerously-set-inner-html';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
import { image_url } from '../../config/Api';
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import HtmlHelmet from '../common/HtmlHelmet';
import { Link } from 'react-router-dom';
// import '../../Styles/OurService.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Htmlcontent from '../Htmlcontent';
import Cookies from 'js-cookie';
import LangData from '../common/LangData';
class InsuranceGroup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      home_layout: {
        content: null
      },
      is_tab_load:true

    }

  }

  // componentDidMount = () => {



  // }

  static getDerivedStateFromProps(props, state) {

    return {
      home_layout: props.home_layout,
      lang:props.lang
    }
  }

  handleTabLoad = (event,tab)=> {
    this.setState({is_tab_load:false});
    setTimeout(() => {
      this.setState({is_tab_load:true});
    }, 200);
  }
  

  render() {

    const content = this.state.home_layout.content;
    console.log("content insurance group =", content);
    var content_data = [];
    var section_data = [];
    if (content.page_data) {

      var page_section = content.page_data.section;

      section_data = page_section.find((row) => {
        if (row.name.toLowerCase() == 'insurance group') {
          return true;
        }
        else {
          return false;
        }
      });
      if (section_data) {
        content_data = section_data.contents;

      }
    }

    var settings = {
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
      loop: true,

    };

    // console.log(" section_data =", section_data);
    console.log(" insurance group lenght=", content_data.length);
    const lang = this.props.lang;
    const is_tab_load = this.state.is_tab_load;

    return (
      <section className="our-service-section padding_70">
        <div className="container">
          <div className="service-tabs">
            {content_data.length > 0 && <Tabs activeTab="1" className="" ulClassName="pro-tab-items" activityClassName="bg-success pro-tab-active" onClick={(event, tab) => this.handleTabLoad(event,tab)}>
              {content_data.length && content_data.map((row, key) => {
                return (
                  <Tab title={lang === 'bn' ? row.bn_name : row.name} className="service-tab-nav" key={key}>
                    {is_tab_load &&<div className="">
                      <Slider
                        {...settings}
                      >
                        {row.contents.length && row.contents.map((crow, ckey) => {
                          return (
                            <div key={ckey}>

                              <h3>
                                {lang === 'en' && <Fragment>{crow.title}</Fragment>}
                                {lang === 'bn' && <Fragment>{crow.bn_title}</Fragment>}
                              </h3>
                              <p>
                                
                                {lang === 'en' && <Fragment>{crow.short_description}</Fragment>}
                                {lang === 'bn' && <Fragment>{crow.bn_short_description}</Fragment>}
                              </p>

                              <div className="tab-slide">

                                <div className="row">
                                  <div className="col-md-6">

                                    {lang === 'en' && <Fragment>
                                      {crow.slider_description && <Htmlcontent data={crow.slider_description} />}
                                    </Fragment>}
                                    {lang === 'bn' && <Fragment>
                                      {crow.bn_slider_description && <Htmlcontent data={crow.bn_slider_description} />}
                                    </Fragment>}


                                    <Link to={{ pathname: 'product/' + crow.slug }} className='btn'>
                                      <LangData keyname="label_learn_more" />
                                    </Link>


                                  </div>
                                  <div className="col-md-6">
                                    {crow.image && <img src={`${image_url}${crow.image}`} />}
                                  </div>
                                </div>

                              </div>

                            </div>
                          )
                        })}

                      </Slider>


                    </div>}
                  </Tab>
                )
              })}
            </Tabs>}
          </div>
        </div>
      </section >
    );
  }
}

const mapStateToProps = (state) => ({
  headers: state.header,
  setting: state.utils.setting,
  home_layout: state.contents.home_layout,
  lang: state.utils.lang

})

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => { dispatch(setPath(requestData)) },
  setLayout: (requestData) => { dispatch(setLayout(requestData)) },
  setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceGroup);