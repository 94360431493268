import React, { Fragment } from 'react';
import BannerLeft from '../BannerLeft';
import BreadCrumbs from '../BreadCrumbs';
import Content from '../Content';
import { useTranslation } from 'react-i18next';


const Common = ({ page_data }) => {
    const { title, bn_title } = page_data;
    /* const {contents :{short_description=""}={}}=page_data; */
    const { bread_curmbs } = page_data;
    const { image: banner_img = "" } = page_data;
    const { contents } = page_data;
    const { i18n } = useTranslation();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    return (
        <Fragment>

            {i18n.resolvedLanguage === 'en' && <Fragment>
                {/* <BannerLeft title={title} menu_name={bread_curmbs[0].title} banner={banner_img} /> */}
            </Fragment>}
            {i18n.resolvedLanguage === 'bn' && <Fragment>
                {/* <BannerLeft title={bn_title} menu_name={bread_curmbs[0].bn_title} banner={banner_img} /> */}
            </Fragment>}

            <BreadCrumbs bread_curmbs={bread_curmbs} />
            <section className="who-we-are-page">
                <div className="container">
                    <div className='page-title'>
                        <h1>
                            {i18n.resolvedLanguage === 'en' && <Fragment>{title}</Fragment>}
                            {i18n.resolvedLanguage === 'bn' && <Fragment>{page_data.bn_title} </Fragment>}
                        </h1>
                    </div>
                    <div className="inner-content">

                        <Content content={contents} />
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Common
