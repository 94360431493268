import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { SetMetaData, userpanelPolicyDetails } from '../../actions/Utils';
import  Header  from './Header';


class PolicyDetails extends Component {
  constructor(props) {
    super(props);
    this.state =
    {
      content: null,
      content_loading: true,
      validator: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      message: '',
      message_type: '',
    }
    this.refs = React.createRef();
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const metadata = {
      meta_title: 'LIC | Product Details',
      meta_description: '',
      meta_keywords: 'ICAB,Change Passwrod',
    }
    this.props.SetMetaData(metadata);



    const { params } = this.props.match;
    const customer = localStorage.getItem('customer');

    if (customer) {
      var customer_data = JSON.parse(customer);
      const requestData = {
        'customer_id': customer_data['customer_id'],
        'id': params.id,
      }

      console.log("requestData =", requestData);
      this.props.userpanelPolicyDetails(requestData).then((data) => {
        this.setState({ content: data })
      })
    }
  }



  // static getDerivedStateFromProps(props, state) {
  //   return {
  //     committe: props.committe
  //   }
  // }

  handleMessageClose = () => {
    this.setState({ message: '', message_type: '' })
  }



  render() {
    const { validator, content } = this.state;

    const renderPaymentCycleMode = (mode) => {
      switch (mode) {
        case '1':
          return 'Yearly';
          break;
        case '2':
          return 'Half Yearly';
          break;
        case '3':
          return 'Quarterly';
          break;
        case '4':
          return 'Monthly';
          break;
        default:
          return '';
      }
    }

    return (
      <Fragment>
        <section class="user-dashboard">

          <div class="container">

            <Header />

            <div class="user-dashboard-details">

              <div class="clearfix"></div>
              {content && <Fragment>

                
                                
                <div class="mt-5 row">
                  <div class="col-md-12">
                    <div class="details-block-single">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>Policy Number</td>
                            <td>{content.core_system_policy_id}</td>
                            <td>Mode</td>
                            <td>
                              {renderPaymentCycleMode(content.payment_cycle_mode)}
                            </td>
                           
                          </tr>
                          <tr>
                            <td>Policy Status Description</td>
                            <td>----</td>
                            <td> Mode DOC (Date of Risk)</td>
                            <td>-----</td>
                          </tr>
                          <tr>
                            <td>Plan Name</td>
                            <td>
                              {content.policy?content.policy.name:''}
                            </td>
                            <td>FUP (Nest Premium Due)</td>
                            <td>----</td>
                          </tr>
                          <tr>
                            <td>Term</td>
                            <td>{content.term}</td>
                            <td>Premium</td>
                            <td>{content.premium_amount}</td>
                          </tr>
                          <tr>
                            <td>PPT (Premium Paying Term)</td>
                            <td>{content.term}</td>
                            <td>Sum Assured</td>
                            <td>{content.sum_assured}</td>
                          </tr>
                          <tr>
                            <td>Nomination Registerd</td>
                            <td>----</td>
                            <td>Branch Name</td>
                            <td>-----</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="details-block-single">
                      <h4>Outasanding Premium Dues</h4>
                      <table class="table">
                      <thead>
                        <tr>
                          <td>No of Dues</td>
                          <td>Total Premium Amount</td>
                          <td>Late fee</td>
                        </tr>
                      </thead>
                        <tbody>
                          <tr>
                            <td>0</td>
                            <td>0.00</td>
                            <td>0.00</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="details-block-single">
                      <h4>Last Premium Paid Details</h4>
                      <table class="table">
                      <thead>
                        <tr>
                          <td>Premium Due</td>
                          <td>Paid Date</td>
                        </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>05/10/2021</td>
                            <td>08/10/2021</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>

                </div></Fragment>}

            </div>


          </div>

        </section>
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
})

const mapDispatchToProps = (dispatch) => ({
  SetMetaData: (requestData) => dispatch(SetMetaData(requestData)),
  userpanelPolicyDetails: (requestData) => dispatch(userpanelPolicyDetails(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PolicyDetails));