
import InnerHTML from 'dangerously-set-inner-html';
import $, { htmlPrefilter } from 'jquery';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFooterSection, getHomePageModal, newsSubscribe } from "../../actions/Utils";
import Htmlcontent from '../Htmlcontent';
import {host_name} from '../../config/Api';
import Cookies from 'js-cookie';
// import '../../Styles/Footer.css';
import LangData from './LangData';
class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            footerdata: {},
            validator: {
                name: '',
                email: '',
                concern: '',
                message: '',
            },
            message: '',
            message_type: '',
            home_modal: '',
            modal_show: false,
            modalLoad: 0,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = () => {
        $(document).ready(function ($) {
            $('.copyright-year').html(new Date().getFullYear());
        });
        this.props.getFooterSection();

        setTimeout(() => {
            this.props.getHomePageModal().then((data) => {
                this.setState({
                    home_modal: data,
                    modal_show: data ? true : false,
                })
            });
        }, 500);
    }

    static getDerivedStateFromProps(props, state) {

        return {
            footerdata: props.footer,
            lang: props.lang,
            setting: props.setting,
        }
    }

    handleMessageClose = () => {
        this.setState({ message: '', message_type: '' })
    }

    modalLoadcount = () => {
        this.setState({ modalLoad: 1 })
    }

    handleSubmit = () => {



        const userFormData = {

            email: this.email.value,

        }

        const resetValidator = {

            email: '',

        }
        var validation = {};

        if (userFormData.email == '') {
            validation.email = 'The email field is required.';
        }



        if (Object.keys(validation).length) {
            this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

        }
        else {


            this.props.newsSubscribe(userFormData).then((res) => {
                this.email.value = '';

                this.setState({ message: res.data.success, message_type: 'success', validator: { ...resetValidator } })
            })
                .catch(error => {


                    if (typeof error.response.data.errors != "undefined") {
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            validation[key] = value;
                        }
                        this.setState({ validator: validation })
                    }
                    else if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data)) {
                                html += `${value}`;
                            }

                        }


                    } else if (error.request) {

                        // console.log('error.request ',error.request);
                    } else {

                        // console.log('Error', error.message);
                    }
                    if (html) {
                        this.setState({ message: html, message_type: 'danger', validator: { ...resetValidator } })
                    }

                })
        }

    }

    handleCloseModal = () => {

        this.setState({
            modal_show: false,
        })
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        return false;
        // if (event.keyCode  == 13 ) {
        //     event.preventDefault();
        //     return false;
        // }
    }
    render() {

        const { footerdata, modal_show, home_modal, modalLoad } = this.state;

        const { validator } = this.state;
        // console.log("footerdata", footerdata);
        const lang = this.props.lang;
        const setting = this.props.setting;
        var placeholder_email_subscribe = lang ==='bn'?"আপনার ইমেইল কি":'What’s your email';
        return (
            <Fragment>

                <footer>

                    {/* <div className="live-chat">
                        <img src={`${host_name}img/icon/chat.svg`} alt="" />
                    </div> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">

                                <div className="footer-location">
                                    {footerdata.footer_address && <Fragment>
                                        {lang === 'en' && <InnerHTML html={footerdata.footer_address[0].contents.description} />}
                                        {lang === 'bn' && <InnerHTML html={footerdata.footer_address[0].contents.bn_description} />}
                                    </Fragment>}
                                </div>
                                <div className="footer-social">



                                    <a href={setting.fb_link} target="_blank">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    {setting.instagram_link && <a href={setting.instagram_link} target="_blank">
                                        <i className="fab fa-instagram"></i>
                                    </a>}

                                    {setting.twitter_link && <a href={setting.twitter_link} target="_blank">

                                        <i className="fab fa-twitter"></i>
                                    </a>}

                                    {setting.linkedin && <a href={setting.linkedin} target="_blank">

                                        <i className="fab fa-linkedin-in"></i>
                                    </a>}
                                    {setting.youtube_link && <a href={setting.youtube_link} target="_blank">

                                        <i className="fab fa-youtube"></i>
                                    </a>}

                                </div>
                                <div className="international-lic">
                                    {footerdata.footer_nternational_lic && <Fragment>
                                        {lang === 'en' && <InnerHTML html={footerdata.footer_nternational_lic[0].contents.description} />}
                                        {lang === 'bn' && <InnerHTML html={footerdata.footer_nternational_lic[0].contents.bn_description} />}
                                    </Fragment>}
                                </div>


                            </div>
                            <div className="col-md-8">
                                <div className="footer-subscribe">
                                    <div className="row">
                                        <div className="col-md-5">
                                            {footerdata.footer_subcribe_content && <Fragment>

                                                {lang === 'en' && <InnerHTML html={footerdata.footer_subcribe_content[0].contents.description} />}
                                                {lang === 'bn' && <InnerHTML html={footerdata.footer_subcribe_content[0].contents.bn_description} />}
                                            </Fragment>}
                                        </div>
                                        <div className="col-md-7">
                                            <form onSubmit={(event) => this.handleFormSubmit(event)} className="float-right">

                                                {this.state.message != '' && this.state.message_type == 'success' && <div className="col-sm-12" >
                                                    <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                                        {this.state.message}
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                </div>}
                                               
                                                {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-sm-12">
                                                    <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                                        {this.state.message}
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                </div>}


                                                <input type="text" ref={(input) => this.email = input} className={validator.email ? 'input-error' : ''} placeholder={placeholder_email_subscribe}  />

                                                
                                                <button type="button" onClick={this.handleSubmit}>{<LangData keyname="label_subscribe"></LangData>}</button>

                                                {validator.email && <Fragment><br></br><span className="help-block form-text text-danger subscribe-error">{validator.email}</span></Fragment>}


                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="insurance-plane-menu">
                                    <ul className="nav">
                                        <li>
                                            <LangData keyname="label_insurance_plans" />
                                        </li>
                                        {footerdata.footer_nav && footerdata.footer_nav.map((row, key) => {
                                            return (
                                                <li key={key}>
                                                    {row.link_type == 0 && <a href={row.url} target={row.url_target}>
                                                        {lang === 'en' && <Fragment>{row.title}</Fragment>}
                                                        {lang === 'bn' && <Fragment>{row.bn_title}</Fragment>}
                                                    </a>}
                                                    {row.link_type == 1 && <Link to={`${row.url}`}>
                                                        {/* {row.title} */}
                                                        {lang === 'en' && <Fragment>{row.title}</Fragment>}
                                                        {lang === 'bn' && <Fragment>{row.bn_title}</Fragment>}
                                                    </Link>}
                                                </li>
                                            )
                                        })}

                                    </ul>
                                </div>
                                <div className="branch-office">
                                    {footerdata.footer_branch_office && <Fragment>
                                        {lang === 'en' && <InnerHTML html={footerdata.footer_branch_office[0].contents.description} />}
                                        {lang === 'bn' && <InnerHTML html={footerdata.footer_branch_office[0].contents.bn_description} />}
                                    </Fragment>}
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="copyright">

                        <div className="container" >


                            <div className="row">
                                <div className="col-md-3">
                                    {footerdata.footer_copy_right && <Fragment>
                                        {lang === 'en' && <InnerHTML html={footerdata.footer_copy_right[0].contents.description} />}
                                        {lang === 'bn' && <InnerHTML html={footerdata.footer_copy_right[0].contents.bn_description} />}
                                    </Fragment>
                                    }
                                </div>
                                <div className="col-md-9">
                                    <ul className="nav float-right">
                                        {footerdata.footer_menu && footerdata.footer_menu.map((row, key) => {
                                            return (
                                                <li key={key}>
                                                    {row.link_type == 0 && <Link to={{
                                                        pathname: `${row.url}`,
                                                    }} target="_blank">
                                                        {lang === 'en' && <Fragment>{row.title}</Fragment>}
                                                        {lang === 'bn' && <Fragment>{row.bn_title}</Fragment>}
                                                    </Link>}
                                                    {row.link_type == 1 && <Link to={`${row.url}`}>
                                                        {/* {row.title} */}
                                                        {lang === 'en' && <Fragment>{row.title}</Fragment>}
                                                        {lang === 'bn' && <Fragment>{row.bn_title}</Fragment>}
                                                    </Link>}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>


                        </div>

                    </div>


                </footer>


            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    footer: state.footer,
    lang: state.utils.lang,
    setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
    getFooterSection: (requestData) => { dispatch(getFooterSection(requestData)) },
    newsSubscribe: (requestData) => dispatch(newsSubscribe(requestData)),
    getHomePageModal: (requestData) => dispatch(getHomePageModal(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

