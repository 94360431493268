
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { image_url } from '../../config/Api';
import Cookies from 'js-cookie';
class ProductSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            home_layout: {
                content: null
            },

        }

    }

    static getDerivedStateFromProps(props, state) {

        return {
            home_layout: props.home_layout,
            lang: props.lang
        }
    }

    render() {
        // const slider = this.props.slider;
        const content = this.state.home_layout.content;
        var slider = [];
        const lang = Cookies.get('lang') ? Cookies.get('lang') : 'bn';

        if (content.page_data) {

            var page_section = content.page_data.section;

            const section_data = page_section.find((row) => {
                if (row.name.toLowerCase() == 'product slider') {
                    return true;
                }
                else {
                    return false;
                }
            });

            if (section_data) {
                var section_content = section_data.contents;
                if (section_content.length) {
                    slider = section_content[0].slider;

                }
                // console.log(" section_content = ", section_content);
            }
        }

        console.log("sliderdata =", slider);

        // console.log('sectioncontent event slider =', sectioncontent);
        var settings = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            arrows: true,
            loop: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 300,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <section className="our-product-section padding_70">


                <div className="container">


                    <div className="product-slider ">
                        <Slider
                            {...settings}
                        >
                            {
                                slider.map((row, key) => {

                                    return (
                                        <Fragment key={key}>
                                            <div>

                                                <div className="our-product-slide">

                                                    {row.internal_link && <Fragment>
                                                        <Link to={{ pathname: row.menu ? row.menu.slug : '' }}>
                                                        <img src={`${image_url}${row.long_image}`} />
                                                        <div className='product-slider-title'>
                                                        <h3>
                                                            


                                                                {lang === 'en' && <Fragment> {row.title.length > 122 ? row.title.substring(0, 125) + '...' : row.title}</Fragment>}
                                                                {(lang === 'bn' && row.bn_title) && <Fragment> {row.bn_title.length > 122 ? row.bn_title.substring(0, 125) + '...' : row.bn_title}</Fragment>}


                                                            
                                                        </h3>
                                                        </div>
                                                        </Link>
                                                    </Fragment>}
                                                    {(row.external_link && row.internal_link == null) && <Fragment>
                                                        <Link to={{ pathname: row.external_link }} target="_parent">
                                                                {row.image && <img src={`${image_url}${row.image}`} />}
                                                        <img src={`${image_url}${row.long_image}`} />
                                                        <div className='product-slider-title'>
                                                        <h3>
                                                          


                                                                {lang === 'en' && <Fragment> {row.title.length > 122 ? row.title.substring(0, 125) + '...' : row.title}</Fragment>}
                                                                {(lang === 'bn' && row.bn_title) && <Fragment> {row.bn_title.length > 122 ? row.bn_title.substring(0, 125) + '...' : row.bn_title}</Fragment>}


                                                            
                                                        </h3>
                                                        </div>
                                                        </Link>
                                                    </Fragment>}
                                                    {(!row.external_link && !row.internal_link) && <Fragment>

                                                        <img src={`${image_url}${row.long_image}`} />
                                                        <div className='product-slider-title'>
                                                        <h3>

                                                            {lang === 'en' && <Fragment> {row.title.length > 122 ? row.title.substring(0, 125) + '...' : row.title}</Fragment>}
                                                            {(lang === 'bn' && row.bn_title) && <Fragment> {row.bn_title.length > 122 ? row.bn_title.substring(0, 125) + '...' : row.bn_title}</Fragment>}


                                                        </h3>
                                                        </div>
                                                    </Fragment>}



                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })

                            }
                        </Slider>
                    </div>

                </div>

            </section>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id: state.utils.layout_id,
    home_layout: state.contents.home_layout,
    lang: state.utils.lang
})



export default connect(mapStateToProps)(ProductSlider);