
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { setIsLogin } from '../../actions/Users';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

const Header = (props) => {

  const { history, match } = props;

  const logOut = () => {
    localStorage.removeItem('customer');
    props.setIsLogin(false);
    ;
    history.push(`/login`);

  }

  const customer = localStorage.getItem('customer');

  if (!customer) {
    history.push(`/login`);
  }

  console.log("Mobarak Hossen");

    return (
      <div className="user-dashboard-header">
        <div className='row'>
          <div className='col-md-3'>
            <h4> Mobarak Hossen</h4>
          </div>
          <div className='col-md-9'>
            <nav className="navbar navbar-expand-lg navbar-light">

              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to={{ pathname: `/dashboard` }} >
                      Dashboard

                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">Purchase New Policy</a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={{ pathname: `/dashboard/change-password` }} >
                      Change Password
                    </Link>
                  </li>

                </ul>
              </div>
            </nav>
            <button type="button" onClick={() => logOut()}>Log Out</button>
          </div>
        </div>

      </div>
    )
  }

  const mapDispatchToProps = dispatch => ({
    setIsLogin: (requestData) => dispatch(setIsLogin(requestData)),
  })

  export default withRouter(connect(null, mapDispatchToProps)(Header));