import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { layoutContentLoad, getSetting, setPathLayout } from "../../actions/Utils";
import Htmlcontent from '../Htmlcontent';
import BreadCrumbs from '../page/BreadCrumbs';
import { image_url } from '../../config/Api';
import LangData from '../common/LangData';


class Paydirectsteptwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            container: {},
            footer: {},
            layout_id: '',
            layout_content: null,

        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { location } = this.props.history;
        var { pathname } = location

        this.props.layoutContentLoad({ 'slug': pathname });

    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            layout_content: props.layout_content
        };
    }
    handleStepChange = () => {
        this.props.history.push('/payment-step-1');
    }
    handleBackHistory = () => {
        this.props.history.push('/paydirect-step-1');
        // console.log("this.props.history =",this.props.history);
        // this.context.router.goBack();
        // this.props.history.goBac;
    }
    getContentData = (menu_id) => {

        const requestData = {
            menu_id: menu_id,
        }
        this.props.sectionandContent(requestData);
    }

    render() {

        console.log("this layout_content = ", this.props.layout_content);
        const layout_content = this.props.layout_content.content;
        var top_html = '';
        var contactus_message = '';
        var contactus_map = '';
        var addresssocialicon = '';

        if (layout_content) {
            const pagesection = layout_content.pagesection;
        }

        const lang = this.props.lang;
        return (
            <Fragment>


                <section class="pay-direct mt-5 mb-5">

                    <div class="container">

                        <h4 class="mb-4">
                        <LangData keyname="label_pay_direct_step2_title" />
                        </h4>

                        <div class="block-items row">
                            <div class="col-md-4">
                                <div class="pay-direct-block">
                                    <h3>1</h3>
                                    <p>
                                        <LangData keyname="label_customer_validation" />
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="pay-direct-block">
                                    <h3>2</h3>
                                    <p>
                                    <LangData keyname="label_premium_perticulars" />
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="pay-direct-block">
                                    <h3>3</h3>
                                    <p>
                                    <LangData keyname="label_payment" />
                                    </p>
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-primary" onClick={()=>this.handleBackHistory()}> <LangData keyname="label_back" /></button>
                        <button class="btn btn-secondary ml-3" onClick={() => this.handleStepChange()}> <LangData keyname="label_proceed" /></button>

                    </div>
                </section>








            </Fragment >
        );
    }
}

const mapStateToProps = state => ({
    layout_content: state.contents.common_content,
    utils: state.utils,
    header: state.header,
    contents: state.contents,
    footer: state.footer,
    layout_id: state.utils.layout_id,
    routemenu: state.utils.routemenu,
    setting: state.utils.setting,
    lang: state.utils.lang
})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    layoutContentLoad: (requestData) => dispatch(layoutContentLoad(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Paydirectsteptwo));