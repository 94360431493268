import $ from "jquery";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  sendContactus,
  setLayout,
  setPath,
  setPathLayout,
  getPoliciesBasedOnAge,
  getTermsBasedOnPolicy,
  getSumAssuredValidation,
  getCalculations,
} from "../../actions/Utils";

import { DateInput } from "./Dateinput";
import "react-datepicker/dist/react-datepicker.css";
import LangData from "../common/LangData";

class PremiumcalCulatorform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validator: {
        dob: "",
        policy: "",
        term: "",
        sum_assured: "",
        parentage: "",
        accidental_cover: "",
        mode: "",
        critical_illness: "",
        gender: "",
        critical_sum: "",
      },
      message: "",
      message_type: "",
      policy: {},
      terms: {},
      modedata: {},
      styleinfo: {
        accidental: "none",
        licterm: "block",
        parentage: "none",
        critical: "none",
        gender: "none",
        critical_sum: "none",
      },
      calculation: "",
      criticalMax: 2000000,
    };
    this.refs = React.createRef();
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      lang: props.lang,
    };
  }

  handleMessageClose = () => {
    this.setState({ message: "", message_type: "" });
  };

  handleDateChange(date) {
    this.setState({
      startDate: date,
    });
  }

  handleSubmit = () => {
    var parentage = $("#parent_age").val();
    var styleinfo = this.state.styleinfo;
    const userFormData = {
      dob: $("#dateofbirth").val(),
      policy: this.policy.value,
      term: styleinfo.licterm == "block" ? this.term.value : 0,
      sum_assured: this.sum_assured.value,
      parentage: parentage && styleinfo.parentage == "block" ? parentage : 0,
      accidental_cover:
        styleinfo.accidental == "block" ? this.accidental_cover.value : 0,
      critical_illness:
        styleinfo.critical == "block" ? this.critical_illness.value : 0,
      gender: styleinfo.gender == "block" ? this.gender.value : 0,
      critical_sum:
        styleinfo.critical_sum == "block" ? this.critical_sum.value : 0,
      mode: this.mode.value,
      phone_number: "",
    };

    const resetValidator = {
      dob: "",
      policy: "",
      term: "",
      sum_assured: "",
      parentage: "",
      accidental_cover: "",
      mode: "",
      critical_illness: "",
      gender: "",
      critical_sum: "",
    };
    var validation = {};
    console.log("userFormData: ", userFormData);
    if (userFormData.dob == "") {
      validation.dob = "This field is required.";
    }

    if (userFormData.policy == "") {
      validation.policy = "This field is required.";
    }

    if (userFormData.term == "" && styleinfo.licterm == "block") {
      validation.term = "This field is required.";
    }
    if (userFormData.sum_assured == "") {
      validation.sum_assured = "This field is required.";
    }
    if (userFormData.parentage == "" && styleinfo.parentage == "block") {
      validation.parentage = "This field is required.";
    }
    if (
      userFormData.accidental_cover == "" &&
      styleinfo.accidental == "block"
    ) {
      validation.accidental_cover = "This field is required.";
    }
    if (userFormData.mode == "") {
      validation.mode = "This field is required.";
    }
    if (this.critical_illness.value == 2) {
      if (userFormData.gender == "") {
        validation.gender = "This field is required";
      }
      if (userFormData.critical_illness == "") {
        validation.critical_illness = "This field is required";
      }
      if (userFormData.critical_sum == "") {
        validation.critical_sum = "This field is required";
      } else {
        let sumAssured = this.sum_assured.value / 2;
        const { criticalMax } = this.state;
        console.log(
          "critical_sum: " + this.critical_sum.value,
          "criticalMax: " + criticalMax,
          "sumAssured: " + sumAssured
        );
        if (
          this.critical_sum.value > sumAssured ||
          this.critical_sum.value > criticalMax
        ) {
          validation.critical_sum =
            "Amount less than or equal to half of the sum assured (MAX: 2000000)";
        }
      }
    }

    if (Object.keys(validation).length) {
      this.setState({
        validator: { ...resetValidator, ...validation },
        message: "",
      });
    } else {
      this.props.getCalculations(userFormData).then((res) => {
        //this.policy.value = "";
        //this.term.value = "";
        this.sum_assured.value = "";
        this.accidental_cover.value = "";
        this.mode.value = "";
        this.critical_sum.value = "";
        this.critical_illness.value = "";
        this.gender.value = "";
        $("#parent_age").val("");
        $("#dateofbirth").val("");

        this.setState({
          calculation: res,
          message: "",
          message_type: "success",
          validator: { ...resetValidator },
        });
      });
    }
  };
  handleValidatonRest = () => {
    const resetValidator = {
      dob: "",
      policy: "",
      term: "",
      sum_assured: "",
      parentage: "",
      accidental_cover: "",
      mode: "",
      critical_illness: "",
      gender: "",
      critical_sum: "",
    };
    this.setState({ validator: { ...resetValidator } });
  };
  handlePoliciesBasedOnAge = () => {
    this.handleValidatonRest();
    var dob = $("#dateofbirth").val();

    if (dob) {
      this.props.getPoliciesBasedOnAge({ dob: dob }).then(({ policies }) => {
        this.setState({ policy: policies });
      });
    }
  };
  handleTermsBasedOnPolicy = () => {
    this.handleValidatonRest();
    var dob = $("#dateofbirth").val();
    var policy = this.policy.value;
    if (dob && policy) {
      this.props
        .getTermsBasedOnPolicy({ dob: dob, policy: policy })
        .then(({ terms }) => {
          this.setState({ terms: terms });
        });
      this.handleModeData();
      this.handleOptionShowHide();
    }
  };
  handleSumAssuredValidation = () => {
    //this.handleValidatonRest();
    var policy = this.policy.value;
    var sum_assured = this.sum_assured.value;

    if (!policy) {
      this.sum_assured.value = "";
      this.setState({
        validator: {
          ...this.state.validator,
          policy: "First you have to select policy",
        },
      });
      return;
    }

    if (sum_assured && policy) {
      this.props
        .getSumAssuredValidation({ policy: policy, sum_assured: sum_assured })
        .then((data) => {
          // console.log("getSumAssuredValidation",data);
          this.critical_sum.value = "";

          if (data.status == "invalid") {
            this.setState({
              validator: {
                ...this.state.validator,
                sum_assured: data.msg,
              },
            });
          } else {
            this.setState({
              validator: {
                ...this.state.validator,
                sum_assured: "",
              },
            });
          }

          //this.setState({terms:terms})
        });
    }
  };
  handleModeData = () => {
    var modeData;
    var policy = this.policy.value;
    if (!policy) {
      modeData = {};
    } else if (policy == "803") {
      modeData = { 5: "Single" };
    } else if (policy == "8141") {
      modeData = { 5: "Single" };
    } else if (policy == "8151") {
      modeData = { 5: "Single" };
    } else if (policy == "809") {
      modeData = { 5: "Single" };
    } else if (policy == "811") {
      modeData = { 1: "Yearly", 2: "Half Yearly" };
    } else {
      modeData = {
        1: "Yearly",
        2: "Half Yearly",
        3: "Quarterly",
        4: "Monthly",
      };
    }

    this.setState({ modedata: modeData });
  };
  handleOptionShowHide = () => {
    var policy = this.policy.value;
    let conditionalData = {
      accidental: "block",
      licterm: "block",
      parentage: "none",
      critical: "none",
      gender: "none",
      critical_sum: "none",
    };

    switch (policy) {
      case "808":
      case "814":
      case "815":
        conditionalData.critical = "block";
        conditionalData.gender = "block";
        break;
      case "807":
        conditionalData.parentage = "block";
        conditionalData.licterm = "none";
        conditionalData.accidental = "none";
        break;
      case "803":
      case "809":
      case "811":
      case "816":
      case "8141":
      case "8151":
        conditionalData.accidental = "none";
        break;
      default:
        conditionalData.parentage = "none";
        conditionalData.licterm = "block";
    }

    /*if (!policy || policy == '807' || policy == '803' || policy == '811' || policy == '816' || policy == '8141' || policy == '809' || policy == '8151') {
            conditionalData.accidental = 'none';
        }*/

    /*if (policy == '807') {
            conditionalData.parentage = 'block';
            conditionalData.licterm = 'none';
        } else {
            conditionalData.parentage = 'none';
            conditionalData.licterm = 'block';
        }*/

    this.setState({
      styleinfo: {
        ...this.state.styleinfo,
        ...conditionalData,
      },
    });
  };

  handleCriticalIllnessChange = () => {
    this.state.styleinfo.critical_sum = "none";
    this.critical_sum.value = "";
    if (this.critical_illness.value == 2) {
      this.state.styleinfo.critical_sum = "block";
    }
    this.setState({
      criticalIllness: this.critical_illness.value,
      styleinfo: {
        ...this.state.styleinfo,
      },
    });
  };

  render() {
    const contents = this.props.data.content;
    const { validator, policy, terms, modedata, styleinfo, calculation } =
      this.state;
    const data_loop = [];
    var cumalitive_total = 0;
    var total_20 = 0;
    var total_40 = 0;
    var totalSurvivalBenefit = 0;
    var totalFac = 0;
    let maturityBenefit = 0;

    // console.log('policy name = ',policy);
    if (calculation) {
      var final_premium_amount;

      if (calculation.mode == "Yearly") {
        final_premium_amount = calculation.premium_amount;
      } else if (calculation.mode == "Half Yearly") {
        final_premium_amount = calculation.premium_amount * 2;
      } else if (calculation.mode == "Quarterly") {
        final_premium_amount = calculation.premium_amount * 4;
      } else if (calculation.mode == "Monthly") {
        final_premium_amount = calculation.premium_amount * 12;
      }

      for (let i = 1; i <= parseInt(calculation.term); i++) {
        total_20 += calculation.sum_assured * 0.02;
        total_40 += calculation.sum_assured * 0.04;
        if (i !== parseInt(calculation.term)) {
          totalSurvivalBenefit += calculation.sum_assured * 0.1;
        }

        if (parseInt(calculation.term) === 6) {
          if (i === 1) {
            totalFac += final_premium_amount * 0.12;
          } else {
            totalFac += final_premium_amount * 0.05;
          }
        } else if (parseInt(calculation.term) === 9) {
          if (i === 1) {
            totalFac += final_premium_amount * 0.18;
          } else if (i === 2) {
            totalFac += final_premium_amount * 0.1;
          } else {
            totalFac += final_premium_amount * 0.05;
          }
        } else if (parseInt(calculation.term) === 12) {
          if (i === 1) {
            totalFac += final_premium_amount * 0.25;
          } else if (i === 2) {
            totalFac += final_premium_amount * 0.1;
          } else {
            totalFac += final_premium_amount * 0.05;
          }
        }

        cumalitive_total += final_premium_amount;
        data_loop.push(cumalitive_total);
      }
    }

    console.log("calculation =", calculation);
    console.log("data_loop =", data_loop);
    console.log("cumalitive_total =", cumalitive_total);

    const policy_data = Object.entries(policy);
    const terms_data = Object.entries(terms);
    const mode_data = Object.entries(modedata);
    console.log("styleinfo =", styleinfo);
    const lang = this.props.lang;
    return (
      <Fragment>
        <div className="contact-from">
          {lang === "en" && (
            <h1>{contents ? contents.short_description : ""}</h1>
          )}
          {lang === "bn" && (
            <h1>{contents ? contents.bn_short_description : ""}</h1>
          )}

          <div className="contact-main">
            <div className="row">
              {this.state.message != "" &&
                this.state.message_type == "success" && (
                  <div className="col-sm-12">
                    <div
                      className="alert  alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      {this.state.message}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => this.handleMessageClose()}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                )}
              {this.state.message != "" &&
                this.state.message_type == "danger" && (
                  <div className="col-sm-12">
                    <div
                      className="alert  alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      {this.state.message}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => this.handleMessageClose()}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                )}
            </div>

            <div className="row">
              <div className="col-md-7">
                <form>
                  <div className="form-group">
                    <label>
                      <LangData keyname="label_premium_calculator_dob" />
                      <span>*</span>
                    </label>

                    <DateInput
                      handlePoliciesBasedOnAge={() =>
                        this.handlePoliciesBasedOnAge()
                      }
                      id="dateofbirth"
                    />

                    {validator.dob && (
                      <span className="help-block form-text text-danger">
                        {validator.dob}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      <LangData keyname="label_premium_calculator_plan_name" />
                      <span>*</span>
                    </label>
                    <select
                      ref={(input) => (this.policy = input)}
                      className={
                        validator.policy
                          ? "form-control from-style input-error"
                          : "form-control from-style"
                      }
                      onChange={() => this.handleTermsBasedOnPolicy()}
                    >
                      <option value="">Select Policy</option>
                      {policy_data.length && (
                        <Fragment>
                          {policy_data.map((row, key) => {
                            return (
                              <option value={row[0]} key={key}>
                                {row[1]}
                              </option>
                            );
                          })}
                        </Fragment>
                      )}
                    </select>

                    {validator.policy && (
                      <span className="help-block form-text text-danger">
                        {validator.policy}
                      </span>
                    )}
                  </div>
                  <div
                    className="form-group"
                    style={{ display: styleinfo.licterm }}
                  >
                    <label>
                      <LangData keyname="label_premium_calculator_term" />
                      <span>*</span>
                    </label>

                    <select
                      ref={(input) => (this.term = input)}
                      className={
                        validator.term
                          ? "form-control from-style input-error"
                          : "form-control from-style"
                      }
                    >
                      <option value="">Select terms</option>

                      {terms_data.length && (
                        <Fragment>
                          {terms_data.map((row, key) => {
                            return (
                              <option value={row[0]} key={key}>
                                {row[1]}
                              </option>
                            );
                          })}
                        </Fragment>
                      )}
                    </select>
                    {validator.term && (
                      <span className="help-block form-text text-danger">
                        {validator.term}
                      </span>
                    )}
                  </div>
                  <div
                    className="form-group"
                    style={{ display: styleinfo.parentage }}
                  >
                    <label>
                      <LangData keyname="label_premium_calculator_date_of_birth_parents" />
                      <span>*</span>
                    </label>
                    <DateInput id="parent_age" />
                    {validator.parentage && (
                      <span className="help-block form-text text-danger">
                        {validator.parentage}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      <LangData keyname="label_premium_calculator_sum_assured" />
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      ref={(input) => (this.sum_assured = input)}
                      className={
                        validator.sum_assured
                          ? "form-control from-style input-error"
                          : "form-control from-style"
                      }
                      placeholder="Sum assured"
                      onInput={() => this.handleSumAssuredValidation()}
                    />
                    {validator.sum_assured && (
                      <span className="help-block form-text text-danger">
                        {validator.sum_assured}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      <LangData keyname="label_premium_calculator_mode" />
                      <span>*</span>
                    </label>
                    <select
                      ref={(input) => (this.mode = input)}
                      className={
                        validator.mode
                          ? "form-control from-style input-error"
                          : "form-control from-style"
                      }
                    >
                      <option value="">Select Mode</option>
                      {mode_data.length && (
                        <Fragment>
                          {mode_data.map((row, key) => {
                            return (
                              <option value={row[0]} key={key}>
                                {row[1]}
                              </option>
                            );
                          })}
                        </Fragment>
                      )}
                    </select>
                    {validator.mode && (
                      <span className="help-block form-text text-danger">
                        {validator.mode}
                      </span>
                    )}
                  </div>
                  <div
                    className="form-group"
                    style={{ display: styleinfo.accidental }}
                  >
                    <label>
                      <LangData keyname="label_premium_calculator_accidental_coverage" />
                      <span>*</span>
                    </label>

                    <select
                      ref={(input) => (this.accidental_cover = input)}
                      className={
                        validator.accidental_cover
                          ? "form-control from-style input-error"
                          : "form-control from-style"
                      }
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    {validator.accidental_cover && (
                      <span className="help-block form-text text-danger">
                        {validator.accidental_cover}
                      </span>
                    )}
                  </div>

                  <div
                    className="form-group"
                    style={{ display: styleinfo.gender }}
                  >
                    <label>
                      <LangData keyname="label_policy_holder_gender" />
                      <span>*</span>
                    </label>

                    <select
                      ref={(input) => (this.gender = input)}
                      className={
                        validator.gender
                          ? "form-control from-style input-error"
                          : "form-control from-style"
                      }
                    >
                      <option value="">Select</option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                    </select>
                    {validator.gender && (
                      <span className="help-block form-text text-danger">
                        {validator.gender}
                      </span>
                    )}
                  </div>

                  <div
                    className="form-group"
                    style={{ display: styleinfo.critical }}
                  >
                    <label>
                      <LangData keyname="label_premium_calculator_critical_illness" />
                      <span>*</span>
                    </label>

                    <select
                      ref={(input) => (this.critical_illness = input)}
                      className={
                        validator.critical_illness
                          ? "form-control from-style input-error"
                          : "form-control from-style"
                      }
                      onChange={(e) => this.handleCriticalIllnessChange()}
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                      <option value="2">Other</option>
                    </select>
                    {validator.critical_illness && (
                      <span className="help-block form-text text-danger">
                        {validator.critical_illness}
                      </span>
                    )}
                  </div>

                  <div
                    className="form-group"
                    style={{ display: styleinfo.critical_sum }}
                  >
                    <label>
                      <LangData keyname="label_premium_calculator_critical_illness_sum_assured" />
                      <span>*</span>
                    </label>
                    <input
                      type="number"
                      min="1"
                      max="2000000"
                      ref={(input) => (this.critical_sum = input)}
                      className={
                        validator.critical_sum
                          ? "form-control from-style input-error"
                          : "form-control from-style"
                      }
                      placeholder="Critical Sum assured"
                    />
                    {validator.critical_sum && (
                      <span className="help-block form-text text-danger">
                        {validator.critical_sum}
                      </span>
                    )}
                  </div>
                </form>

                <div className="submit">
                  <input
                    type="button"
                    value={lang === "bn" ? "ক্যালকুলেট" : "Calculate"}
                    className="btn submit-button"
                    name="submit"
                    onClick={() => this.handleSubmit()}
                  />
                </div>
              </div>
              <div className="col-md-5">
                {calculation && (
                  <div className="calculated-result">
                    <p>
                      <span className="label">
                        <LangData keyname="label_premium_calculator_plan_name" />
                        :{" "}
                      </span>{" "}
                      {calculation.policy}
                    </p>
                    <p>
                      <span className="label">
                        {" "}
                        <LangData keyname="label_premium_calculator_customer_age" />
                        :
                      </span>{" "}
                      {calculation.age}
                    </p>
                    <p>
                      <span className="label">
                        <LangData keyname="label_premium_calculator_sum_assured" />
                        :
                      </span>{" "}
                      {calculation.sum_assured}
                    </p>
                    <p>
                      <span className="label">
                        <LangData keyname="label_premium_calculator_term" />:
                      </span>{" "}
                      {calculation.term}
                    </p>
                    <p>
                      <span className="label">
                        <LangData keyname="label_premium_calculator_mode" />:
                      </span>{" "}
                      {calculation.mode}
                    </p>
                    <p>
                      <span className="label">
                        <LangData keyname="label_premium_calculator_approximate_premium" />
                        :{" "}
                      </span>
                      {calculation.premium_amount}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* this for LIC Jibon Swapno */}
        {calculation.policy == "LIC Jibon Swapno" && (
          <div className="cumalative_data mt-4">
            <table className="table">
              <tr>
                <td>Year</td>
                <td>Premium Payable</td>
                <td>Cumulative paid premium</td>
                <td>Payable on Death before Maturity date</td>
                <td>Critical Illness Benefit</td>
                <td>Maturity Benefit</td>

                <td>Maturity payable if BDT 20 Bonus is declared</td>
                <td>Maturity payable if BDT 40 Bonus is declared</td>
              </tr>

              {data_loop.length &&
                data_loop.map((row, key) => {
                  return (
                    <tr id={key}>
                      <td>{key + 1}</td>
                      <td>{calculation.premium_amount}</td>
                      <td>{row}</td>
                      {key == 0 && (
                        <td
                          rowSpan={data_loop.length}
                          style={{ verticalAlign: "middle" }}
                        >
                          {calculation.sum_assured}+Bonus
                        </td>
                      )}
                      {key == 0 && (
                        <td
                          rowSpan={data_loop.length}
                          style={{ verticalAlign: "middle" }}
                        >
                          {calculation.critical_illness_amount}
                        </td>
                      )}
                      {key == 0 && <td rowSpan={data_loop.length}></td>}
                      <td>{calculation.sum_assured * 0.02}</td>
                      <td>{calculation.sum_assured * 0.04}</td>
                    </tr>
                  );
                })}
              <tr>
                <td colSpan={5}>
                  On maturity date at the end of {calculation.term} year
                </td>

                <td>{calculation.sum_assured}+Bonus</td>

                <td>
                  {parseInt(calculation.sum_assured) + parseInt(total_20)}
                </td>
                <td>
                  {parseInt(calculation.sum_assured) + parseInt(total_40)}
                </td>
              </tr>
            </table>
          </div>
        )}
        {/* this for LIC Jibon Swapno */}
        {calculation.policy == "LIC Jibon Swapno-SinglePremium" && (
          <div className="cumalative_data mt-4">
            <table className="table">
              <tr>
                <td>Year</td>
                <td>Age</td>
                <td>Single Premium</td>
                <td>Payable on Death before Maturity date</td>
                <td>Maturity Benefit</td>

                <td>Maturity payable if BDT 20 Bonus is declared</td>
                <td>Maturity payable if BDT 40 Bonus is declared</td>
              </tr>

              {data_loop.length &&
                data_loop.map((row, key) => {
                  return (
                    <tr id={key}>
                      <td>{key + 1}</td>
                      <td>{calculation.age + key}</td>
                      {key == 0 && (
                        <td
                          rowSpan={data_loop.length}
                          style={{ verticalAlign: "middle" }}
                        >
                          {calculation.premium_amount}
                        </td>
                      )}

                      {key == 0 && (
                        <td
                          rowSpan={data_loop.length}
                          style={{ verticalAlign: "middle" }}
                        >
                          {calculation.sum_assured}+Bonus
                        </td>
                      )}
                      {key == 0 && <td rowSpan={data_loop.length}></td>}

                      <td>{calculation.sum_assured * 0.02}</td>
                      <td>{calculation.sum_assured * 0.04}</td>
                    </tr>
                  );
                })}
              <tr>
                <td colSpan={4}>
                  On maturity date at the end of {calculation.term} year
                </td>

                <td>{calculation.sum_assured}+Bonus</td>

                <td>
                  {parseInt(calculation.sum_assured) + parseInt(total_20)}
                </td>
                <td>
                  {parseInt(calculation.sum_assured) + parseInt(total_40)}
                </td>
              </tr>
            </table>
          </div>
        )}
        {calculation.policy == "LIC Jibon Bijoy" && (
          <div className="cumalative_data mt-4">
            <table className="table">
              <tr>
                <td>Year</td>
                <td>Yearly Premium</td>
                <td>Total paid premium at year end</td>
                <td>Survival Benefit</td>
                <td>Payable On Death Maturity Date</td>
                <td>Critical Illness Benefit</td>
                <td>Maturity Benefit</td>
                <td>Maturity payable if BDT 50 Bonus is declared</td>
                <td>FAC</td>
              </tr>

              {data_loop.length &&
                data_loop.map((row, key) => {
                  return (
                    <tr id={key}>
                      <td>{key + 1}</td>
                      <td>{final_premium_amount}</td>
                      <td>{final_premium_amount * (key + 1)}</td>

                      {parseInt(calculation.term) === 6 ? (
                        key + 1 === 2 || key + 1 === 4 ? (
                          <td style={{ verticalAlign: "middle" }}>
                            {calculation.sum_assured * 0.25}
                          </td>
                        ) : (
                          <td style={{ verticalAlign: "middle" }}>
                            {/* Content for the outer else condition */}
                          </td>
                        )
                      ) : parseInt(calculation.term) === 9 ? (
                        key + 1 === 3 || key + 1 === 6 ? (
                          <td style={{ verticalAlign: "middle" }}>
                            {calculation.sum_assured * 0.25}
                          </td>
                        ) : (
                          <td style={{ verticalAlign: "middle" }}>
                            {/* Content for the outer else condition */}
                          </td>
                        )
                      ) : parseInt(calculation.term) === 12 ? (
                        key + 1 === 4 || key + 1 === 8 ? (
                          <td style={{ verticalAlign: "middle" }}>
                            {calculation.sum_assured * 0.25}
                          </td>
                        ) : (
                          <td style={{ verticalAlign: "middle" }}>
                            {/* Content for the outer else condition */}
                          </td>
                        )
                      ) : (
                        <td style={{ verticalAlign: "middle" }}>
                          {/* Content for the outer else condition */}
                        </td>
                      )}

                      {key == 0 && (
                        <td
                          rowSpan={data_loop.length}
                          style={{ verticalAlign: "middle" }}
                        >
                          {calculation.sum_assured} + Bonus
                        </td>
                      )}

                      {key == 0 && (
                        <td
                          rowSpan={data_loop.length}
                          style={{ verticalAlign: "middle" }}
                        >
                          {calculation.critical_illness_amount}
                        </td>
                      )}

                      {key == 0 && (
                        <td
                          rowSpan={data_loop.length}
                          style={{ verticalAlign: "middle" }}
                        ></td>
                      )}

                      <td>{calculation.sum_assured * 0.05}</td>
                      {parseInt(calculation.term) === 6 ? (
                        key === 0 ? (
                          <td style={{ verticalAlign: "middle" }}>
                            {(final_premium_amount * 0.12).toFixed(2)}
                          </td>
                        ) : (
                          <td style={{ verticalAlign: "middle" }}>
                            {(final_premium_amount * 0.05).toFixed(2)}
                          </td>
                        )
                      ) : parseInt(calculation.term) === 9 ? (
                        key === 0 ? (
                          <td style={{ verticalAlign: "middle" }}>
                            {(final_premium_amount * 0.18).toFixed(2)}
                          </td>
                        ) : key === 1 ? (
                          <td style={{ verticalAlign: "middle" }}>
                            {(final_premium_amount * 0.1).toFixed(2)}
                          </td>
                        ) : (
                          <td style={{ verticalAlign: "middle" }}>
                            {(final_premium_amount * 0.05).toFixed(2)}
                          </td>
                        )
                      ) : parseInt(calculation.term) === 12 ? (
                        key === 0 ? (
                          <td style={{ verticalAlign: "middle" }}>
                            {(final_premium_amount * 0.25).toFixed(2)}
                          </td>
                        ) : key === 1 ? (
                          <td style={{ verticalAlign: "middle" }}>
                            {(final_premium_amount * 0.1).toFixed(2)}
                          </td>
                        ) : (
                          <td style={{ verticalAlign: "middle" }}>
                            {(final_premium_amount * 0.05).toFixed(2)}
                          </td>
                        )
                      ) : (
                        <td style={{ verticalAlign: "middle" }}>
                          {/* Content for the outer else condition */}
                        </td>
                      )}
                    </tr>
                  );
                })}
              <tr>
                <td colSpan={6}>
                  On maturity date at the end of {calculation.term} year
                </td>
                <td>
                  {parseInt(calculation.sum_assured) -
                    calculation.sum_assured * 0.25 * 2}{" "}
                  + Bonus
                </td>
                <td>
                  {" "}
                  {calculation.sum_assured * 0.25 * 2} +{" "}
                  {calculation.sum_assured * 0.05 * calculation.term} ={" "}
                  {calculation.sum_assured * 0.25 * 2 +
                    calculation.sum_assured * 0.05 * calculation.term}
                </td>
                <td>{totalFac.toFixed(2)}</td>
              </tr>
            </table>
          </div>
        )}
        {calculation.policy == "LIC Jibon Bijoy-SinglePremium" && (
          <div className="cumalative_data mt-4">
            <table className="table">
              <tr>
                <td>Year</td>
                <td>Age</td>
                <td>Single Premium</td>
                <td>Survival Benefit</td>
                <td>Payable on Death Maturity date</td>
                <td>Maturity Benefit</td>
                <td>Maturity payable if BDT 40 Bonus is declared</td>
                <td>FAC</td>
              </tr>

              {data_loop.length &&
                data_loop.map((row, key) => {
                  return (
                    <tr id={key}>
                      <td>{key + 1}</td>
                      <td>{calculation.age + key}</td>
                      {key == 0 && (
                        <td
                          rowSpan={data_loop.length}
                          style={{ verticalAlign: "middle" }}
                        >
                          {calculation.premium_amount}
                        </td>
                      )}

                      {key + 1 != calculation.term ? (
                        <td style={{ verticalAlign: "middle" }}>
                          {calculation.sum_assured * 0.1}
                        </td>
                      ) : (
                        <td style={{ verticalAlign: "middle" }}>
                          {/* Content for the else condition */}
                        </td>
                      )}

                      {key == 0 && (
                        <td
                          rowSpan={data_loop.length}
                          style={{ verticalAlign: "middle" }}
                        >
                          {calculation.sum_assured}+Bonus
                        </td>
                      )}
                      {key == 0 && <td rowSpan={data_loop.length}></td>}
                      <td style={{ verticalAlign: "middle" }}>
                        {(calculation.sum_assured * 0.04).toFixed(2)}
                      </td>
                      {key == 0 ? (
                        <td style={{ verticalAlign: "middle" }}>
                          {(calculation.premium_amount * 0.03).toFixed(2)}
                        </td>
                      ) : (
                        <td style={{ verticalAlign: "middle" }}></td>
                      )}
                    </tr>
                  );
                })}
              <tr>
                <td colSpan={4}>
                  On maturity date at the end of {calculation.term} year
                </td>
                <td>{calculation.sum_assured}+Bonus</td>
                <td>
                  {parseInt(calculation.sum_assured) -
                    parseInt(totalSurvivalBenefit)}{" "}
                  + Bonus
                </td>
                <td>
                  {parseInt(calculation.sum_assured) -
                    parseInt(totalSurvivalBenefit)}{" "}
                  + {parseInt(total_40)} ={" "}
                  {parseInt(calculation.sum_assured) -
                    parseInt(totalSurvivalBenefit) +
                    parseInt(total_40)}
                </td>
                <td>{(calculation.premium_amount * 0.03).toFixed(2)}</td>
              </tr>
            </table>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.utils.lang,
});
const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => {
    dispatch(setPath(requestData));
  },
  setLayout: (requestData) => {
    dispatch(setLayout(requestData));
  },
  setPathLayout: (requestData) => {
    dispatch(setPathLayout(requestData));
  },
  sendContactus: (requestData) => {
    dispatch(sendContactus(requestData));
  },
  getPoliciesBasedOnAge: (requestData) =>
    dispatch(getPoliciesBasedOnAge(requestData)),
  getTermsBasedOnPolicy: (requestData) =>
    dispatch(getTermsBasedOnPolicy(requestData)),
  getSumAssuredValidation: (requestData) =>
    dispatch(getSumAssuredValidation(requestData)),
  getCalculations: (requestData) => dispatch(getCalculations(requestData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PremiumcalCulatorform);
