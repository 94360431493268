import axios from 'axios';
import API from '../config/Api';
import qs from 'qs';
import axiosInstance,{ version } from '../config/Api';
export const addUser = (params) => {

  // console.log('adduser');
  }

  export const loginUser = (params) => {
      
      return (dispatch) => {
        return axiosInstance.post(version +'get/login',qs.stringify(params)).then(res => {
            return res.data;
        });
    }
  }

  export const register = (params) => {
    
    return (dispatch) => {
      return axiosInstance.post('auth/users/',qs.stringify(params)).then(res => {
          return res.data;
      });
  }
}
export const signUpByPolicy = (params) => {
    
    return (dispatch) => {
      return axiosInstance.post(version +'signup/policy',qs.stringify(params)).then(res => {
          return res.data;
      });
  }
}
export const verifyPin = (params) => {
    
    return (dispatch) => {
      return axiosInstance.post(version +'signup/verify-pin',qs.stringify(params)).then(res => {
          return res.data;
      });
  }
}
export const signUp = (params) => {
    
    return (dispatch) => {
      return axiosInstance.post(version +'signup/registration',qs.stringify(params)).then(res => {
          return res.data;
      });
  }
}
export const getUserInfo = (params) => {
    
    return (dispatch) => {
      return axiosInstance.post(version +'get/userInfo',qs.stringify(params)).then(res => {
          return res.data;
      });
  }
}

export const loginUserd = (params) => {
    // console.log(" data user = ",params)
    return (dispatch) => {
      return axiosInstance.post('users', qs.stringify(params)).then(res => {
          return res.data;
      });
  }
}



export const addLoginUser = (user) => ({
  type: 'SET_LOGEDUSER',
  user
});

export const getLogedUser = () => {
      
  return (dispatch) => {
    return axiosInstance.get('auth/users/me/').then(res => {
      // console.log(" res.data = ",res.data)
      if(Object.keys(res.data).length) {
         axiosInstance.defaults.headers.common["User-ID"] = res.data.id;
          dispatch(addLoginUser(res.data));
      }
        return res.data;
    });
}
}

export const setIsLogin = (status) => ({
  type: 'SET_IS_LOGIN',
  status
});

export const setlogout = (user) => ({
  type: 'SET_LOGOUT'
});

export const logout = () => {
      
  return (dispatch) => {
    return axiosInstance.post('auth/token/logout/').then(res => {
      // console.log(" res.data logout = ",res.data)
      localStorage.removeItem('token');
      axiosInstance.defaults.headers.common["Authorization"] = '';
      dispatch(setlogout());
      return res.data;
    });
}
}
export const forgetPassword = (params) => {
      
  return (dispatch) => {
    return axiosInstance.post(version +'get/forgotPassword',qs.stringify(params)).then(res => {
        return res.data;
    });
  }
}
export const otpVerification = (params) => {
      
  return (dispatch) => {
    return axiosInstance.post(version +'get/otpVerification',qs.stringify(params)).then(res => {
        return res.data;
    });
  }
}
export const resetUserPassword = (params) => {
      
  return (dispatch) => {
    return axiosInstance.post(version +'get/resetPassword',qs.stringify(params)).then(res => {
        return res.data;
    });
  }
}