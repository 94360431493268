import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from 'react-router-dom';
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
import { image_url } from '../../config/Api';
// import '../../Styles/HomeSlider.css'
import LangData from '../common/LangData';
import Cookies from 'js-cookie';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
class CarouselSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            home_layout: {
                content: null
            },

        }

    }

    // componentDidMount = () => {



    // }

    static getDerivedStateFromProps(props, state) {

        return {
            home_layout: props.home_layout,
            lang: props.lang
        }
    }

    render() {

        const content = this.state.home_layout.content;
        var is_video = 0;
        var slider = [];
        var video_url = '';
        const lang = Cookies.get('lang') ? Cookies.get('lang') : 'bn';
        if (content.page_data) {

            var page_section = content.page_data.section;

            var section_data = page_section.find((row) => {
                if (row.name.toLowerCase() == 'home slider') {
                    return true;
                }
                else {
                    return false;
                }
            });
            if (section_data) {
                var section_content = section_data.contents;
                if (section_content.length) {
                    slider = section_content[0].slider;
                    is_video = slider[0].is_video;
                    video_url = slider[0].video;
                }
                // console.log(" section_content = ", section_content);
            }
        }

        return (

            <section>
                {
                    is_video == 1 ?
                        <div className="video-slider" >
                            {/* <h1>{section_data.title}</h1>
                            <div>

                                <video loop={true} autoPlay={true} controls={false} muted>
                                    <source src={image_url + video_url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                            </div> */}
                                <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                                    <iframe
                                        src="https://player.vimeo.com/video/803044257?autoplay=0&loop=1&muted=0&autopause=0"
                                        style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                                        frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture;"
                                        allowFullScreen
                                        id="introVideo"
                                    />
                                </div>
                        </div>
                        :
                        <div className="main-slider">
                            <div className='banner-slider '>
                                <Carousel
                                    autoPlay={true}
                                    showThumbs={false}
                                    infiniteLoop
                                    showArrows={false}
                                    selectedItem={0}
                                    showStatus={false}
                                >
                                    {slider.length && slider.map((row, key) => {

                                        return (
                                            <Fragment key={key}>

                                                {


                                                    <div>



                                                        <BrowserView>
                                                            <img src={`${image_url}${row.long_image}`} />
                                                        </BrowserView>
                                                        <MobileView>
                                                            <img src={`${image_url}${row.thumb_image}`} />
                                                        </MobileView>

                                                        <div className="container">

                                                            <div className="slider-caption">

                                                                <h1>

                                                                    {lang === 'en' && <Fragment>{row.title}</Fragment>}
                                                                    {lang === 'bn' && <Fragment>{row.bn_title}</Fragment>}
                                                                </h1>

                                                                <p>
                                                                    {lang === 'en' && <Fragment>{row.short_descripton}</Fragment>}
                                                                    {lang === 'bn' && <Fragment>{row.bn_short_descripton}</Fragment>}
                                                                </p>


                                                                {row.external_link && <Link to={{
                                                                    pathname: row.external_link,

                                                                }} className='btn float-left'>

                                                                    <LangData keyname="label_learn_more" />
                                                                </Link>}

                                                                {row.internal_link && <Link to={{
                                                                    pathname: row.menu ? row.menu.slug : '',

                                                                }} className='btn float-left'>
                                                                    <LangData keyname="label_learn_more" />
                                                                </Link>}

                                                                {/* <div className="video-popup">
                                                                    <a href="#" data-toggle="modal" data-target="#videopopup"><i className="fa fa-play"></i></a>
                                                                </div> */}

                                                            </div>

                                                        </div>

                                                    </div>
                                                }
                                            </Fragment>

                                        )


                                    })}


                                </Carousel>
                            </div>
                        </div>
                }
            </section>
        );
    }
}


const mapStateToProps = (state) => ({
    headers: state.header,
    setting: state.utils.setting,
    lang: state.utils.lang,
    home_layout: state.contents.home_layout,
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(CarouselSlider);