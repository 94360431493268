import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import { layoutContentLoad, getSetting, setPathLayout } from "../../actions/Utils";
import Stepfourform from './Stepfourform';
import Htmlcontent from '../Htmlcontent';
import BreadCrumbs from '../page/BreadCrumbs';
import { image_url } from '../../config/Api';
import LangData from '../common/LangData';


class Paydirectstepfour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            container: {},
            footer: {},
            layout_id: '',
            layout_content: null,
            redirect_back:false,
        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { location } = this.props.history;
        var { pathname,state } = location
        console.log("customer =",location);
        // this.props.layoutContentLoad({ 'slug': pathname });

        if(state === undefined ) {
            this.setState({redirect_back:true});
        } 

    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            layout_content: props.layout_content
        };
    }
    handleStepChange = () => {
        var step = this.renewal_premium.value;
        if (step) {
            this.props.history.push('/payment-step-1');
        }


    }

    handleBackHistory = () => {
        this.props.history.push('/payment-step-1');
       
    }
    getContentData = (menu_id) => {

        const requestData = {
            menu_id: menu_id,
        }
        this.props.sectionandContent(requestData);
    }

    render() {

        console.log("this layout_content = ", this.props.layout_content);
        const layout_content = this.props.layout_content.content;
     

        if (layout_content) {
            const pagesection = layout_content.pagesection;
        }

        console.log("Hello four=",this.props);

        const lang = this.props.lang;

        if(this.state.redirect_back === true) {
            return <Redirect to={{
                pathname: '/payment-step-1'
            }} />
        }

        return (
            <Fragment>

                <section class="pay-direct mt-5 mb-5">

                    <div class="container">
                        <Stepfourform handleBackHistory= {this.handleBackHistory}/>
                    </div>

                </section>
            </Fragment >
        );
    }
}

const mapStateToProps = state => ({
    layout_content: state.contents.common_content,
    utils: state.utils,
    header: state.header,
    contents: state.contents,
    footer: state.footer,
    layout_id: state.utils.layout_id,
    routemenu: state.utils.routemenu,
    setting: state.utils.setting,
    lang: state.utils.lang
})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    layoutContentLoad: (requestData) => dispatch(layoutContentLoad(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Paydirectstepfour));