import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { pageSectionContent, getSetting, setPathLayout } from "../../actions/Utils";

import Htmlcontent from '../../components/Htmlcontent';
import BreadCrumbs from '../../components/page/BreadCrumbs';
import { image_url, host_name } from '../../config/Api';
import BranchList from './BranchList';

class Branch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            container: {},
            footer: {},
            layout_id: '',
            branch_data: {},
            slug: '',
        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { location } = this.props.history;
        const { path } = this.props.match;
        console.log('props =', this.props);
        this.props.pageSectionContent({ 'slug': path }).then((data) => {
            console.log("pageSectionContent =", data);
            this.setState({
                branch_data: data,
                slug: path,
            })
        });

    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            lang:props.lang

        };
    }
    getContentData = (menu_id) => {

        const requestData = {
            menu_id: menu_id,
        }
        this.props.pageSectionContent(requestData);
    }

    render() {


        const { branch_data } = this.state;
        const { pagesection } = branch_data;

        var contents = [];
        var section_data = {};
        if (pagesection) {



            section_data = pagesection.find((row) => {

                if (row.section && row.section.name) {
                    if (row.section.name.toLowerCase() == 'branch module') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });
            if (section_data) {
                contents = section_data.section.contents;

            }
        }

        // console.log('pagesection contents=', contents);
        // console.log('pagesection section_data =', section_data);
        var lang = this.props.lang;
        return (
            <Fragment>



                {/* <section className="innerpage-header">

                    {(branch_data && branch_data.image) ? <img src={`${image_url}${branch_data.image}`} /> : <img src={`${host_name}img/default-innerpage-header.png`} />}



                    <div className="container">

                        <div className="innerpage-header-content">



                            {branch_data && branch_data.title && <h3>
                               
                                {lang == 'en' &&<Fragment> {branch_data.title}</Fragment>}
                                {lang == 'bn' &&<Fragment> {branch_data.bn_title}</Fragment>}
                                </h3>}

                            

                        </div>

                    </div>

                </section> */}


                {branch_data && branch_data.bread_cumbs && <BreadCrumbs bread_curmbs={branch_data.bread_cumbs} />}


                <section class="branch-locator">

                    <div class="container">

                    <div className='page-title'>
                        

                        {branch_data && branch_data.title && <h1>
                               
                               {lang == 'en' &&<Fragment> {branch_data.title}</Fragment>}
                               {lang == 'bn' &&<Fragment> {branch_data.bn_title}</Fragment>}
                               </h1>}
                    </div>

                        <div class="row">
                           
                           {contents && contents.map((row,key)=>{
                               return(
                                <Fragment key={key}>
                                  
                                    <BranchList data={row} lang={lang}/>
                                </Fragment>
                               )
                           })}
                            
                         
                        </div>

                    </div>
                </section>


            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    premiumcalculatorlayout: state.contents.premiumcalculator_layout,
    utils: state.utils,
    header: state.header,
    contents: state.contents,
    footer: state.footer,
    layout_id: state.utils.layout_id,
    routemenu: state.utils.routemenu,
    setting: state.utils.setting,
    lang:state.utils.lang

})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    pageSectionContent: (requestData) => dispatch(pageSectionContent(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Branch));