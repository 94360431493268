import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { loginUser, getLogedUser, setIsLogin } from '../../actions/Users';
// import auth from "../Auth";
import { Redirect, useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios'
import axiosInstance, { encryption_salt } from '../../config/Api';
import {
    Link
} from "react-router-dom";
import BannerLeft from '../page/BannerLeft';
import BreadCrumbs from '../page/BreadCrumbs';
import InnerHTML from 'dangerously-set-inner-html';
import LangData from '../common/LangData';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validator: {
                'username': '',
                'password': '',
            },
            'message': '',
            'message_type': '',
            'is_login': false
        };
        this.refs = React.createRef();
    }

    componentDidMount = () => {

        var forgot_user_cookies_header_user = window.btoa("ForgotUserName" + encryption_salt).toString();
        var cookies_forgot_user = Cookies.get(forgot_user_cookies_header_user);
        if (cookies_forgot_user) {
            Cookies.remove(cookies_forgot_user);
        }
    }

    handleLogin = () => {


        const userFormData = {
            'username': this.refs.username.value,
            'password': this.refs.password.value,
        }

        const resetValidator = {
            'username': '',
            'password': '',
        }
        var validation = {};

        if (userFormData.username == '') {
            //validation.username = 'The user name field is required.';
        }

        if (userFormData.password == '') {
            //validation.password = 'The password field is required.';
        }

        if (Object.keys(validation).length) {
            this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

        }
        else {
            var thiss = this;
            this.props.loginUser(userFormData).then(res => {

                console.log("login staus=", res);
                // if (res.auth_token) {
                //     localStorage.setItem('token', res.auth_token)
                //     axiosInstance.defaults.headers.common["Authorization"] = 'Token ' + res.auth_token;
                //     // thiss.props.getLogedUser();
                //     thiss.props.setIsLogin(true)

                // }

                if (res.status == 'success') {
                    this.refs.username.value = '';
                    this.refs.password.value = '';
                    localStorage.setItem('customer', JSON.stringify(res.customer));
                    this.setState({ message: res.message, message_type: 'success', validator: { username: '', password: '' }, is_login: true })
                    thiss.props.setIsLogin(true);
                }
                else {
                    this.setState({ message: res.message, message_type: 'danger', validator: { username: '', password: '' }, is_login: false })
                }






            })
                .catch(error => {
                    if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                                html += value + '</br>';
                            }

                        }
                        // console.log(' error.response.data ',error.response.data);
                        // console.log(' error.response.status ',error.response.status);
                        // console.log('error.response.headers ',error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the 
                        // browser and an instance of
                        // http.ClientRequest in node.js
                        // console.log('error.request ',error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        // console.log('Error', error.message);
                    }
                    if (html) {
                        this.setState({ message: html, message_type: 'danger', validator: { username: '', password: '' } })
                    }

                })
        }


    }
    getLoginUserInformation = () => {
        var user = this.props.getLogedUser();

    }

    handleMessageClose = () => {
        this.setState({ message: '', message_type: '' })
    }

    handleSubmit = async event => {
        event.preventDefault();

        //
        //const response = await API.delete(`users/${this.state.id}`);

        // console.log(response);
        // console.log(response.data);
    };

    render() {
        //console.log("data = ",this.state);
        const { validator } = this.state;
        if (this.state.is_login) {
            return <Redirect to='dashboard' />
        }
        const { lang } = this.props;
        const bread_cumbs = [
            {
                bn_title: "সাইন ইন",
                title: "Sign In",
                url: "/login",
            },
            {
                bn_title: "হোম",
                title: "Home",
                url: "/",
            }
        ]
        return (
            <Fragment>

                {/* <BannerLeft title={lang == 'bn' ? 'সাইন ইন' : 'Sign In'} /> */}
                <BreadCrumbs bread_curmbs={bread_cumbs} />
                <section className="sign-up">
                    <div className="container">




                        <form>

                            {this.state.message != '' && this.state.message_type == 'success' && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                                <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                    <InnerHTML html={this.state.message} />
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>}
                            {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                                <div className="alert  alert-danger alert-dismissible fade show" role="alert">

                                    <InnerHTML html={this.state.message} />
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>}
                            {lang === 'en' && <Fragment><h3>Sign In</h3></Fragment>}
                            {lang === 'bn' && <Fragment><h3>সাইন ইন</h3></Fragment>}
                            <div className="row">

                                <div className="col-md-12">
                                    <input type="text" id="email_address" placeholder='User name' ref="username" className="form-control" required autoFocus />
                                    {validator.username && <span className="help-block form-text text-danger">{validator.username}</span>}
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-md-12">
                                    <input type="password" ref="password" placeholder='Password' id="password" className="form-control" required />
                                    {validator.password && <span className="help-block form-text text-danger">{validator.password}</span>}
                                </div>
                            </div>

                            <p>
                                <Link to={{ pathname: `/forgot-password` }}>
                                    <LangData keyname="label_forgot_username_password_text" />
                                </Link>
                            </p>

                            <div className="col-md-12">
                                <button type="button" className="btn btn-primary btn-block" onClick={() => this.handleLogin()}>

                                    <LangData keyname="label_login" />
                                </button>

                            </div>
                        </form>

                    </div>
                </section>
            </Fragment>
        );
    }
}


const mapStateToProps = state => ({
    lang: state.utils.lang
})

const mapDispatchToProps = dispatch => ({
    loginUser: (requestData) => dispatch(loginUser(requestData)),
    getLogedUser: (requestData) => dispatch(getLogedUser(requestData)),
    setIsLogin: (requestData) => dispatch(setIsLogin(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);