import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { withRouter } from "react-router-dom";
import { getGallerys,SetMetaData } from '../../actions/Utils';
import { image_url } from '../../config/Api';
import NoImage from '../../img/no_image.jpeg';
import ImageGallery from './ImageGallery'
// import ImgsViewer from 'react-images'
// import ImgsViewer from "react-images-viewer";





class PhotoGallery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      content_loading: true,
      albumid: ''
    }
    this.handleLoadContent = this.handleLoadContent.bind(this);

  }

  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    var id = this.props.match.params.id;

    const requestData = {
      page: 1,
      album_type_id: 1,
      album_id: id,
    }

    this.props.getGallerys(requestData).then((res) => {
      this.setState({
        content: res,
        content_loading: false,
        albumid: id
      })
    });

    const metadata = {
      meta_title:'ICAB | Photo Gallery',
      meta_description:'',
      meta_keywords:'ICAB,Photo Gallery',
    }
    this.props.SetMetaData(metadata);
  }


  // static getDerivedStateFromProps(props, state) {

  //   return false;
  // }

  handleLoadContent = () => {

    const { content, slug } = this.state;
    const current_page = content.current_page;

    const requestData = {
      page: current_page + 1,
      album_type_id: 1,
      album_id: this.state.albumid,
    }
    const req = this.props.getGallerys(requestData).then((data) => {

      if (Object.keys(data).length) {
        const getcontent = data;
        const newData = data.data;
        delete data.data;

        var newState = {
          ...this.state,
          content: {
            ...data,
            data: [
              ...this.state.content.data,
              ...newData
            ]
          }
        }
        this.setState({
          ...newState
        })
      }


    });

  }
  render() {


    const { content, content_loading } = this.state;


    console.log(" content =", content);
    return (

      <Fragment>

        <section className="gallery">



          <div className="container">

            <div className="inner-content">

              <div className="gallery-content">

                {/* <h1>{sec_content.section.title}</h1> */}

                <div className="row">
                  {content_loading ? <Fragment>Loading...</Fragment> : <Fragment>
                    {Object.keys(content).length ? <Fragment>
                    
                   <ImageGallery content = {content.data}/>

                    </Fragment> : 'No data'}

                  </Fragment>}
                </div>

                {Object.keys(content).length ? <Fragment>
                  {content.to < content.total && <Fragment>

                    <button className="btn btn-primary" onClick={this.handleLoadContent}>Load More</button>
                  </Fragment>}
                </Fragment> : ''}

              </div>

            </div>

          </div>

        </section>
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
})

const mapDispatchToProps = (dispatch) => ({
  getGallerys: (requestData) => dispatch(getGallerys(requestData)),
  SetMetaData: (requestData) => dispatch(SetMetaData(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PhotoGallery));