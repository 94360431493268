
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { image_url } from '../../config/Api';
// import '../../Styles/Differenctcontent.css';
import Cookies from 'js-cookie';
class DifferentContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            home_layout: {
                content: null
            },

        }

    }

    static getDerivedStateFromProps(props, state) {

        return {
            home_layout: props.home_layout,
            lang: props.lang
        }
    }

    render() {
        // const slider = this.props.slider;
        const content = this.state.home_layout.content;
        var contentList = [];
        const lang = Cookies.get('lang') ? Cookies.get('lang') : 'bn';

        if (content.page_data) {

            var page_section = content.page_data.section;

            const section_data = page_section.find((row) => {
                if (row.name.toLowerCase() == 'different content') {
                    return true;
                }
                else {
                    return false;
                }
            });

            if (section_data) {
                var section_content = section_data.contents;
                if (section_content.length) {
                    contentList = section_content[0].list;
                }
                // console.log(" section_content = ", section_content);
            }
        }

        // console.log("contentList =",contentList);



        return (
            <section className="important-content-section">
                <div className="container">
                    <div className="row">
                        {contentList.map((row, key) => {
                            return (
                                <div className="col-md-4" key={key}>

                                    <div className="important-content-single">
                                        {row.internal_link && <Fragment>
                                            <Link to={{ pathname: row.menu ? row.menu.slug : '' }}>
                                                {row.image && <img src={`${image_url}${row.image}`} />}
                                                <p>

                                                    {lang === 'en' && <Fragment>{row.description}</Fragment>}
                                                    {lang === 'bn' && <Fragment>{row.bn_description}</Fragment>}
                                                </p>
                                                <h3>
                                                    {lang === 'en' && <Fragment>{row.title}</Fragment>}
                                                    {lang === 'bn' && <Fragment>{row.bn_title}</Fragment>}
                                                </h3>
                                            </Link>
                                        </Fragment>}
                                        {(row.external_link && row.internal_link == null) && <Fragment>
                                            <Link to={{ pathname: row.external_link  }} target="_blank">
                                                {row.image && <img src={`${image_url}${row.image}`} />}
                                                <p>

                                                    {lang === 'en' && <Fragment>{row.description}</Fragment>}
                                                    {lang === 'bn' && <Fragment>{row.bn_description}</Fragment>}
                                                </p>
                                                <h3>
                                                    {lang === 'en' && <Fragment>{row.title}</Fragment>}
                                                    {lang === 'bn' && <Fragment>{row.bn_title}</Fragment>}
                                                </h3>
                                            </Link>
                                        </Fragment>}
                                        {(! row.external_link && !row.internal_link) && <Fragment>
                                            
                                                {row.image && <img src={`${image_url}${row.image}`} />}
                                                <p>

                                                    {lang === 'en' && <Fragment>{row.description}</Fragment>}
                                                    {lang === 'bn' && <Fragment>{row.bn_description}</Fragment>}
                                                </p>
                                                <h3>
                                                    {lang === 'en' && <Fragment>{row.title}</Fragment>}
                                                    {lang === 'bn' && <Fragment>{row.bn_title}</Fragment>}
                                                </h3>
                                          
                                        </Fragment>}

                                    </div>
                                </div>
                            )
                        })}



                    </div>
                </div>
            </section>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id: state.utils.layout_id,
    home_layout: state.contents.home_layout,
    lang: state.utils.lang
})



export default connect(mapStateToProps)(DifferentContent);