
import * as crypto from 'crypto-js';
import $ from 'jquery';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { SetMetaData } from "../actions/Utils";
import { forgetPassword, otpVerification } from '../actions/Users';
import '../App.css';
import LangData from '../components/common/LangData';
import BreadCrumbs from '../components/page/BreadCrumbs';
import axiosInstance, { caversion, encryption_salt } from '../config/Api';
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validator: {
        userid: '',
        usertype: '',
        random_number: '',
        user_email: '',
        user_mobile: '',
      },
      varify_otp_input: [
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' '
      ],
      message: '',
      message_type: '',
      otp_sent: false,
      btn_show: false
    };
    this.refs = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleMessageClose = () => {
    this.setState({ message: '', message_type: '' })
  }
  componentDidMount = () => {

    const metadata = {
      meta_title: 'LIC | Forgot passwrod',
      meta_description: '',
      meta_keywords: 'LIC,Forgot passwrod',
    }
    this.props.SetMetaData(metadata);



    $(function () {
      var body = $('body');
      function goToNextInput(e) {
        var key = e.which,
          t = $(e.target),
          sib = t.next('.forgot-password-form input');
        if (key != 9 && (key < 48 || key > 57) && (key < 96 || key > 105)) {
          e.preventDefault();
          return false;
        }
        if (key === 9) {
          return true;
        }
        if (!sib || !sib.length) {
          sib = body.find('.forgot-password-form input').eq(0);
        }
        sib.select().focus();
      }
      function onKeyDown(e) {
        var key = e.which;
        if (key === 9 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
          return true;
        }
        e.preventDefault();
        return false;
      }
      function onFocus(e) {
        $(e.target).select();
      }

      body.on('keyup', '.forgot-password-form input', goToNextInput);
      body.on('keydown', '.forgot-password-form input', onKeyDown);
      body.on('click', '.forgot-password-form input', onFocus);

    });


    var forgot_user_cookies_header_user = window.btoa("ForgotUserName" + encryption_salt).toString();
    var cookies_forgot_user = Cookies.get(forgot_user_cookies_header_user);
    if (cookies_forgot_user) {
      this.setState({ otp_sent: true });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    var random_number = Math.floor(100000 + Math.random() * 900000);
    const userFormData = {
      userid: this.userid.value,
      usertype: 10,
      random_number: random_number,
      user_email: ''
    }

    const resetValidator = {
      userid: ''
    }
    var validation = {};

    if (userFormData.userid == '') {
      validation.userid = 'The username field is required.';
    }

    if (Object.keys(validation).length) {
      this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

    }
    else {
      const requestData = {
        username: userFormData.userid,
      }
      this.props.forgetPassword(requestData).then((data) => {

        if (data.status == 'success') {
          axiosInstance.post(caversion + 'forgot-password-mail-sent', requestData).then((res) => {




            var forgot_user_cookies_header_user = window.btoa("ForgotUserName" + encryption_salt).toString();
            var user_id_encrypt = crypto.AES.encrypt("" + userFormData.userid + "", encryption_salt).toString();
            Cookies.set(forgot_user_cookies_header_user, user_id_encrypt);
            this.setState({ message: 'Email sent sucessfully.', message_type: 'success', validator: { ...resetValidator }, otp_sent: true })
          })
            .catch(error => {

              if (error.response) {
                var html = '';
                if (Object.keys(error.response.data).length) {
                  for (const [key, value] of Object.entries(error.response.data)) {
                    //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                    html += `${value}`;
                  }

                }

              }
              if (html) {
                // this.setState({ message: error.response.data.message, message_type: 'danger', validator: { ...resetValidator } })
                this.setState({ message: 'Something wrong! Please try again.', message_type: 'danger', validator: { ...resetValidator } })
              }
            })
        }
        else {
          this.setState({ message: data.message, message_type: 'danger', validator: { ...resetValidator } })
        }


      }).catch(error => {
        validation.userid = 'Username not match';
        this.setState({ validator: { ...resetValidator, ...validation }, message: '' });
      });
    }
  }
  handleOtpVerification = (e) => {

    e.preventDefault();

    var forgot_user_cookies_header_user = window.btoa("ForgotUserName" + encryption_salt).toString();
    var user_name_encrypt = Cookies.get(forgot_user_cookies_header_user);
    var username = crypto.AES.decrypt(user_name_encrypt, encryption_salt);
    username = username.toString(crypto.enc.Utf8);

    const token = this.state.varify_otp_input;
    let otp_code = '';
    for (let x in token) {
      if (token[x].trim()) {
        otp_code += token[x];
      }
    }
    if (otp_code.length == 6) {
      const requestData = {
        user_name: username,
        otp_code: otp_code,
      }

      this.props.otpVerification(requestData).then((data) => {
        
        if (data.status == 'success') {
          var forgot_user_otp_varrified = window.btoa("ForgotUserIDOTPVarrified" + encryption_salt).toString();
          var flag_encrypt = crypto.AES.encrypt("1", encryption_salt).toString();
          Cookies.set(forgot_user_otp_varrified, flag_encrypt);
          
          const { history } = this.props;
          history.push(`/reset-password`);
          console.log("otpVerification=", data);
        }
        else {
          this.setState({ message: data.message, message_type: 'danger' });
        }

      }).catch(error => {

        this.setState({ message: 'You have something Wrong !!', message_type: 'danger' });
      });





    }
  }
  change_varify_otp_input = (e, sl) => {

    const { varify_otp_input } = this.state;
    const working_variffy = [...varify_otp_input];
    working_variffy[sl] = e.target.value;
    this.setState((prevState) => {
      return {
        varify_otp_input: working_variffy,
      };
    }, () => {
      const token = this.state.varify_otp_input;
      let text = '';
      for (let x in token) {
        if (token[x].trim()) {
          text += token[x];
        }


      }
      if (text.length === 6) {
        this.setState({ btn_show: true });
      }
      else {
        this.setState({ btn_show: false });
      }

    });
  }
  goto_forgot_page = (e) => {
    var user_cookie_name = window.btoa("ForgotUserIDUser" + encryption_salt).toString();
    Cookies.remove(user_cookie_name);

    var randorm_number_name = window.btoa("ForgotUserID" + encryption_salt).toString();
    Cookies.remove(randorm_number_name);
    this.setState({ otp_sent: false });
  }
  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  }
  getSetting = async () => {
    let data = await this.props.getSetting();
  }
  render() {
    const { validator } = this.state;
    const bread_cumbs = [
      {
        bn_title: "পাসওয়ার্ড ভুলে গেছেন",
        title: "Forgot Password",
        url: "/login",
      },
      {
        bn_title: "হোম",
        title: "Home",
        url: "/",
      }
    ]

    const { lang } = this.props;
    // console.log("varify_otp_input =", this.state.varify_otp_input);
    return (
      <Fragment>
        <BreadCrumbs bread_curmbs={bread_cumbs} />
        <section className="sign-up">
          {
            !this.state.otp_sent ?

              <div className="container">


                <div className="sign-in-form">
                  <form onSubmit={this.handleSubmit}>



                    {lang === 'en' && <Fragment><h3>Forgot Password</h3></Fragment>}
                    {lang === 'bn' && <Fragment><h3>পাসওয়ার্ড ভুলে গেছেন</h3></Fragment>}


                    <div className="form-title">
                      {this.state.message != '' && this.state.message_type == 'success' && <div className="col-sm-12" >
                        <div className="alert  alert-success alert-dismissible fade show" role="alert">
                          {this.state.message}
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>}
                      {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-sm-12">
                        <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                          {this.state.message}
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>}
                    </div>


                    <div className="form-group">
                      <label><LangData keyname="label_username" /> <span>*</span></label>
                      <input type="text" ref={(input) => this.userid = input} className={validator.userid ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="e.g. mini" />
                      {validator.userid && <span className="help-block form-text text-danger">{validator.userid}</span>}
                    </div>
                    <input type="submit" value={lang == 'bn' ? 'দাখিল করুন' : 'Submit'} className="btn btn-primary" />
                  </form>
                </div>
              </div>
              :
              <div className="forgot-password-wrapper">

                <div className="form-title">
                  {this.state.message != '' && this.state.message_type == 'success' && <div className="col-sm-12" >
                    <div className="alert  alert-success alert-dismissible fade show" role="alert">
                      {this.state.message}
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>}
                  {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-sm-12">
                    <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                      {this.state.message}
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>}
                </div>

                <div className="forgot-password-dialog">

                 
                  <h3><LangData keyname="label_opt_code_message" /></h3>
                  <div className="forgot-password-form">
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e) => this.change_varify_otp_input(e, 0)} />
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e) => this.change_varify_otp_input(e, 1)} />
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e) => this.change_varify_otp_input(e, 2)} />
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e) => this.change_varify_otp_input(e, 3)} />
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e) => this.change_varify_otp_input(e, 4)} />
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e) => this.change_varify_otp_input(e, 5)} />
                    {
                      this.state.btn_show ?
                        <button className="btn btn-primary btn-embossed" onClick={(e) => this.handleOtpVerification(e)}><LangData keyname="label_reset_password" /></button>
                        : ''
                    }
                  </div>


                  <div>
                    <LangData keyname="label_opt_code_didnot_message" /><br />
                    <a href="" onClick={(e) => this.goto_forgot_page(e)}><LangData keyname="label_opt_code_again_message" /></a><br />
                  </div>
                </div>
              </div>
          }
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  setting: state.utils.setting,
  lang: state.utils.lang
})

const mapDispatchToProps = dispatch => ({
  forgetPassword: (requestData) => dispatch(forgetPassword(requestData)),
  otpVerification: (requestData) => dispatch(otpVerification(requestData)),
  SetMetaData: (requestData) => dispatch(SetMetaData(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));