import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

const MultiMenus = ({ menus, lang }) => {
  // console.log("menus=",menus);

  const [activeMenus, setActiveMenus] = useState([]);

  const handleMenuClick = (data) => {
    console.log(data);
  };

  const handleArrowClick = (menuName) => {
    // console.log("before menuName=",activeMenus);
    let newActiveMenus = [...activeMenus];

    if (newActiveMenus.includes(menuName)) {
      var index = newActiveMenus.indexOf(menuName);
      if (index > -1) {
        newActiveMenus.splice(index, 1);
      }
    } else {
      newActiveMenus.push(menuName);
    }
    // console.log("after menuName=",newActiveMenus);
    setActiveMenus(newActiveMenus);
  };

  const ListMenu = ({ dept, data, hasSubMenu, menuName, menuIndex }) => (
    <li
      className={`${
        dept == 1 && hasSubMenu && data.title.toLowerCase() != "products"
          ? "second-label "
          : ""
      }${
        dept == 1 && hasSubMenu && data.title.toLowerCase() == "products"
          ? "mega-menu "
          : ""
      }${hasSubMenu ? "menu-dropdown-icon " : ""}`}
    >
      <Fragment dept={dept}>
        {data.sub_nav && data.sub_nav.length > 0 ? (
          <Link to={"#"}>
            {lang === "en" && <Fragment>{data.title}</Fragment>}
            {lang === "bn" && <Fragment>{data.bn_title}</Fragment>}
          </Link>
        ) : (
          ""
        )}
        {data.sub_nav && data.sub_nav.length == 0 ? (
          <Fragment>
            {data.link_type == 1 && (
              <Link to={{ pathname: `${data.url}` }}>
                {lang === "en" && <Fragment>{data.title}</Fragment>}
                {lang === "bn" && <Fragment>{data.bn_title}</Fragment>}
              </Link>
            )}
            {data.link_type == 0 && (
              <Link
                to={{ pathname: `${data.url}` }}
                target={data.url_target ? data.url_target : "_blank"}
              >
                {lang === "en" && <Fragment>{data.title}</Fragment>}
                {lang === "bn" && <Fragment>{data.bn_title}</Fragment>}
              </Link>
            )}
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
      {hasSubMenu && (
        <SubMenu
          dept={dept}
          data={data.sub_nav}
          toggle={activeMenus.includes(menuName)}
          menuIndex={menuIndex}
        />
      )}
    </li>
  );

  const SubMenu = ({ dept, data, toggle, menuIndex }) => {
    console.log("toggle =", toggle, "dept=", dept, "data=", data);
    dept = dept + 1;
    return (
      <ul
        className={`${data.length && dept == 2 ? "third-label " : ""}${
          data.length && dept == 3 ? "fourth-label " : ""
        }`}
      >
        {data.map((menu, index) => {
          console.log("Menu:SubNav: ", menu.sub_nav);
          const menuName = `parent-submenu-${dept}-${menuIndex}-${index}`;
          return (
            <ListMenu
              dept={dept}
              data={menu}
              hasSubMenu={menu.sub_nav && menu.sub_nav.length ? menu.sub_nav : false}
              menuName={menuName}
              key={menuName}
              menuIndex={index}
            />
          );
        })}
      </ul>
    );
  };

  return (
    <ul className="clearfix headermenu desktop">
      {menus.map((menu, index) => {
        const dept = 1;
        const menuName = `parent-menu-${dept}-${index}`;

        return (
          <ListMenu
            dept={dept}
            data={menu}
            hasSubMenu={menu.sub_nav.length ? menu.sub_nav : false}
            menuName={menuName}
            key={menuName}
            menuIndex={index}
          />
        );
      })}
    </ul>
  );
};

export default MultiMenus;
