import $ from 'jquery';
import qs from 'qs';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
    sendContactus, setLayout, setPath, setPathLayout,
    paymentCustomerAmountValidation,
} from '../../actions/Utils';
import axiosInstance, { version } from '../../config/Api';

import DatePicker from "react-datepicker";
import { DateInput } from '../contactus/Dateinput';
import "react-datepicker/dist/react-datepicker.css";
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router'
import Cookies from 'js-cookie'
import LangData from '../common/LangData';

class Stepfourform extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validator: {
                policy_number: '',
                policy_holder_dob: '',
                policy_mobilenumber: '',
                policy_email: '',
                user_agree: '',
                premium_amount: ''
            },
            message: '',
            message_type: '',

        };
        // this.refs = React.createRef();

        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleMessageClose = () => {
        this.setState({ message: '', message_type: '' })
    }

    handleDateChange(date) {
        this.setState({
            startDate: date
        })
    }

    componentDidMount = () => {
        // const { location } = this.props.history;
        // const {customer} = location.state;

    }

    // handleSubmit = () => {

    //     const is_agree = this.user_agree.checked;
    //     console.log('is_agree =',is_agree);

    // }

    handleSubmit = () => {

      


        const { formdata, validator } = this.state;

        const userFormData = {
            premium_amount: this.premium_amount.value,
        }
       

        const resetValidator = {
            premium_amount:''
        }
        var validation = {};

        if (userFormData.premium_amount == '') {
            validation.premium_amount = 'The amount field is required.';
        }

        if (Object.keys(validation).length) {
            this.setState({ validator: { ...resetValidator, ...validation }, message: '', });
        }
        else {
            var error_validate = {};
            this.props.paymentCustomerAmountValidation(userFormData).then((res) => {

                // $("#paymentform").trigger('reset');

                const {location} = this.props.history;
                var { pathname,state } = this.props.location;
                console.log("customer =",location);
               
        
                if(state !== undefined && state.customer && res.status == 'success') {
                    this.setState({redirect_back:true});
               
                    var payres = state.customer;
                    payres['premium_amount'] = userFormData['premium_amount'];
                    payres['cus_code'] = 'pd'; // pd = Payment Direct
                    this.handlePayOnline(payres);
                    
                }
                else {
                    this.setState({ message: res.message, message_type: 'danger', validator: { ...resetValidator } })
                }


               
            })
                .catch(error => {
                    console.log("Hello Policy form =", error);
                    if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                                html += value + '</br>';
                                if (typeof validator[key] != "undefined") {
                                    // error_validate[key] = `${value}`;
                                    error_validate[`${key}`] = `${value}`;
                                    // Object.assign(error_validate, {key: value});
                                }

                            }

                        }
                        // console.log(' error.response.data ',error.response.data);
                        // console.log(' error.response.status ',error.response.status);
                        // console.log('error.response.headers ',error.response.headers);
                    }

                    console.log("error_validate =", error_validate);
                    console.log("validator =", validator);
                    this.setState({ validator: { ...validation, ...error_validate } })
                })
        }
    }





    dateFormate = (date) => {

        var today = new Date(date);
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return today = yyyy + '/' + mm + '/' + dd;

    }

    handlePayOnline = (policycustomer) => {

        axiosInstance.post(version + 'pay_online', { params: policycustomer }).then(({ data }) => {
            console.log(" hanldePayonline =", data);
            if (data.status == 'SUCCESS' && data.GatewayPageURL) {
                Cookies.set('cus_id', policycustomer.id);
                window.location.href = data.GatewayPageURL;
            }
            else {
                this.setState({
                    message: 'Sorry, System did not payment successfully. Please contact with your contact person.', message_type: 'danger',
                })
            }

        });
    }

    render() {

        // const contents = this.props.data.content;
        const { validator, formdata } = this.state;

        // return <Redirect to={{
        //     pathname: '/login',
        //     search: '?utm=your+face',
        //     state: { referrer: '' }
        // }} />


        return (
            <Fragment>
                <form action="#" id="paymentform">

                    <div className="mt-3">
                        {(this.state.message != '' && this.state.message_type == 'success') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                {this.state.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                        {(this.state.message != '' && this.state.message_type == 'danger') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                {this.state.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                        {/* <form> */}
                        <div class="row">
                            <div class="col-md-6">
                                <label> <LangData keyname="label_amount" /><span>*</span></label>
                                <input type="text" placeholder="Amount" ref={(input) => this.premium_amount = input} class="form-control" />

                                {validator.premium_amount && <span className="help-block form-text text-danger">{validator.premium_amount}</span>}

                            </div>
                        </div>

                        <button class="btn btn-primary" onClick={() => this.props.handleBackHistory()}><LangData keyname="label_back" /> </button>
                        <button class="btn btn-secondary ml-3" type='button' onClick={() => this.handleSubmit()}><LangData keyname="label_paynow" /></button>



                        {/* </form> */}
                    </div>

                </form>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
    sendContactus: (requestData) => { dispatch(sendContactus(requestData)) },
    paymentCustomerAmountValidation: (requestData) => dispatch(paymentCustomerAmountValidation(requestData)),
})

export default withRouter(connect(null, mapDispatchToProps)(Stepfourform));