import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { productDetails, getSetting, setPathLayout } from "../../actions/Utils";

import Htmlcontent from '../../components/Htmlcontent';
import BreadCrumbs from '../../components/page/BreadCrumbs';
import { image_url, host_name } from '../../config/Api';
import HtmlHelmet from '../common/HtmlHelmet';
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';

import Cookies from 'js-cookie';

class ProductDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            container: {},
            footer: {},
            layout_id: '',
            product_details: null,
            product_load: false,
            slug: '',
        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { location } = this.props.history;
        const { slug } = this.props.match.params;
        this.props.productDetails({ 'slug': slug }).then((data) => {
       
            this.setState({
                product_details: data,
                product_load: true,
                slug: slug,
            })
        });

    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            premiumcalculatorlayout: props.premiumcalculatorlayout,
            lang: props.lang
        };
    }
    getContentData = (menu_id) => {

        const requestData = {
            menu_id: menu_id,
        }
        this.props.sectionandContent(requestData);
    }

    render() {


        const { product_details, product_load } = this.state;
        const lang = this.props.lang;
        // console.log("product_details =", product_details, 'product_load =', product_load, 'state =', this.state);
        var meta_data = {};
        if(product_load == true && product_details.data != null) {
             meta_data = {
                meta_title:product_details.data.title,
                meta_description:product_details.data.short_description,
                meta_keywords:product_details.data.title,
              }
        }
        


        return (
            <Fragment>
                
                {(product_load == true && product_details.data != null) &&<HtmlHelmet custom_meta = {meta_data} />}
                {/* <section className="innerpage-header">

                    <img src={`${host_name}img/product-details-innerpage-header.png`} />

                    <div className="container">

                        <div className="innerpage-header-content">

                        
                            {(product_details && product_details.innner_header_title && lang == 'en') && <h3>{product_details.innner_header_title}</h3>}
                            {(product_details && product_details.innner_header_bn_title && lang == 'bn') && <h3>{product_details.innner_header_bn_title}</h3>}

                           

                        </div>

                    </div>

                </section> */}


                {product_details && product_details.bread_cumbs && <BreadCrumbs bread_curmbs={product_details.bread_cumbs} />}

                {(product_load == true && product_details.data != null) &&
                    <section className="our-service-details">

                        <div className="container">
                            {product_details && product_details.data && <Fragment><div className="product-details-title">
                                <h3>
                                    {lang === 'en' && <Fragment>{product_details.data.title}</Fragment>}
                                    {lang === 'bn' && <Fragment>{product_details.data.bn_title}</Fragment>}
                                </h3>
                                <p className="mb-5">
                                    {lang === 'en' && <Fragment>{product_details.data.short_description}</Fragment>}
                                    {lang === 'bn' && <Fragment>{product_details.data.bn_short_description}</Fragment>}
                                </p></div>
                            </Fragment>}
                            <div className='service-tabs'>
                                {product_details && product_details.contents && <Tabs activeTab="1" className="" ulClassName="pro-tab-items" activityClassName="bg-success pro-tab-active">
                                    {product_details.contents && product_details.contents.map((row, key) => {
                                        return (
                                            <Tab title={lang === 'bn' ? row.bn_category_name : row.category_name} className="service-tab-nav" key={key}>
                                                <div className="product-details-page">
                                                    {row.content && row.content.map((crow, ckey) => {
                                                        return (
                                                            <Fragment key={ckey}><div className='product-details'>
                                                                <div className='row'>
                                                                    <div className='col-md-8'>
                                                                        {lang === 'en' && <Fragment> <Htmlcontent data={crow.description} /></Fragment>}
                                                                        {lang === 'bn' && <Fragment> <Htmlcontent data={crow.bn_description} /></Fragment>}
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        {crow.image && <img className='product-image' src={`${image_url}${crow.image}`} />}
                                                                    </div>


                                                                </div>

                                                            </div>

                                                            </Fragment>
                                                        )
                                                    })}

                                                    {/* {key === 0 && <div className="tab-button">

                                                        <Link to={{ pathname: '/policy-buy' }} className="btn btn-buynow">Buy Now</Link>
                                                    </div>} */}
                                                </div>
                                            </Tab>
                                        )
                                    })}
                                </Tabs>}
                            </div>
                        </div>
                    </section>}

                {product_load == true && product_details.data == null && <Fragment>
                    <div className='container'>
                        <div className="norecordfound">
                            No record found.
                        </div>
                    </div>


                </Fragment>}


            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    premiumcalculatorlayout: state.contents.premiumcalculator_layout,
    utils: state.utils,
    header: state.header,
    contents: state.contents,
    footer: state.footer,
    layout_id: state.utils.layout_id,
    routemenu: state.utils.routemenu,
    setting: state.utils.setting,
    lang: state.utils.lang,

})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    productDetails: (requestData) => dispatch(productDetails(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDetails));