import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {premiumCalculatorLayoutContent, getSetting, setPathLayout} from "../actions/Utils";
import PremiumcalCulatorform from '../components/contactus/PremiumcalCulatorform';
import Htmlcontent from '../components/Htmlcontent';
import BreadCrumbs from '../components/page/BreadCrumbs';
import {image_url} from '../config/Api';


class PremiumCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            container: {},
            footer: {},
            layout_id: '',
            premiumcalculatorlayout: null,
        };
    }

    componentDidMount = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
        const {location} = this.props.history;
        var {pathname} = location

        this.props.premiumCalculatorLayoutContent({'slug': pathname});

    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }

    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            premiumcalculatorlayout: props.premiumcalculatorlayout
        };
    }

    getContentData = (menu_id) => {

        const requestData = {
            menu_id: menu_id,
        }
        this.props.sectionandContent(requestData);
    }

    render() {

        //  console.log("this premiumcalculatorlayout = ", this.props.premiumcalculatorlayout);
        const calculatorlayout = this.props.premiumcalculatorlayout.content;
        var top_html = '';
        var contactus_message = '';
        var contactus_map = '';
        var addresssocialicon = '';

        if (calculatorlayout) {
            const pagesection = calculatorlayout.pagesection;
        }

        return (
            <Fragment>
                <section className="premium-calculator">
                    <div className="container">
                        <section className="contact-from-section">
                            <PremiumcalCulatorform data={ calculatorlayout }/>
                        </section>
                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    premiumcalculatorlayout: state.contents.premiumcalculator_layout,
    utils: state.utils,
    header: state.header,
    contents: state.contents,
    footer: state.footer,
    layout_id: state.utils.layout_id,
    routemenu: state.utils.routemenu,
    setting: state.utils.setting,

})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    premiumCalculatorLayoutContent: (requestData) => dispatch(premiumCalculatorLayoutContent(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PremiumCalculator));